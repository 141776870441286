<template>
    <div class="hotizontal-select">

        <button v-for="(option, index) in options"
         :key="index"
         :class="['horizontal-select-option', { optSelected: selected === option.id }]"
         @click="select(option.id)">
            {{ option.name }}
        </button>

    </div>
</template>
<script>
export default {
    name: 'HorizontalSelect',
    props: {
        options: {
            type: Array,
            default: () => []
        },
        value: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            selected: this.value
        }
    },
    watch: {
        value(val) {
            this.selected = val
        }
    },
    methods: {
        select(option) {
            this.selected = option
            this.$emit('input', option)
        }
    }
}
</script>
<style>
.hotizontal-select {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 100%;
    gap: 10px;
    overflow-x: scroll;
}

.hotizontal-select::-webkit-scrollbar {
    display: none;
}

.horizontal-select-option {
    font-family: var(--font);
    font-size: 1.2rem;
    font-weight: 600;
    padding: 10px 20px;
    background-color: var(--dark-gray);
    color: black;
    border-radius: var(--card-br);
    transition: all 0.3s ease;
    white-space: nowrap;
    user-select: none;
}

.horizontal-select-option:active {
    transform: scale(0.95);
}

.optSelected {
    background-color: var(--dark-blue);
    color: white;
}

@media screen and (max-width: 400px) {
    .horizontal-select-option {
        font-size: 1rem;
    }
}
</style>
