<template>
    <div class="lang-select">
        <ul>
            <li v-for="lang in this.$i18n.availableLocales"
             @click="changeLang(lang)"
             :id="lang"
             :class="(lang === this.$i18n.locale) ? 'selected' : ''"
             :key="lang">{{ langName(lang) }}</li>
        </ul>
    </div>
</template>

<script>

export default {
    name: 'LanguageSelect',
    data() {
        return {

        }
    },
    methods: {
        changeLang(id) {
            const selected = document.getElementById(id);
            var lang = document.querySelector('.selected');
            lang.classList.remove('selected');
            selected.classList.add('selected');
            this.$i18n.locale = id;
            localStorage.setItem('lang', id);
            this.$router.go();
        },
        mountLang() {
            const selected = document.getElementById(this.$i18n.locale);
            var lang = document.querySelector('.selected');
            lang.classList.remove('selected');
            selected.classList.add('selected');
        },
        langName(lang) {
            switch (lang) {
                case 'en':
                    return 'English';
                case 'et':
                    return 'Eesti';
                case 'ru':
                    return 'Русский';
                default:
                    return 'English';
            }
        }
    },
    mounted() {
        this.mountLang();
    }

}
</script>

<style scoped>
.lang-select {
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    padding: 10px;
    z-index: 110;
    border-radius: 8px;
    width: 200px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.selected {
    border: 2px solid var(--dark-blue);
}

ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: 100%;
}

li {
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    border-radius: 10px;
    background-color: var(--gray);
    padding: 10px;
    display: flex;
    border: 2px solid transparent;
    justify-content: flex-start;
    align-items: center;
    transition: all 0.2s ease-in-out;
}

li:hover {
    background-color: var(--dark-gray);
}
</style>
