<template>
    <div class="gray-card">
        <div class="card-img-group">
            <img class="card-img"
             :src="image"
             alt="gray card picture">
        </div>
        <div class="text-group">
            <h3 class="textgroup-heading">{{ title }}</h3>
            <p class="textgroup-text">
                {{ text }}
            </p>
        </div>
    </div>

</template>

<script>


export default {
    name: 'GrayPanel',
    props: {
        title: {
            type: String,
            default: 'Some title'
        },
        text: {
            type: String,
            default: 'Some text'
        },
        image: {
            type: String,
            default: 'https://35fit.com/images/add/add_1_2.png'
        }
    },




};
</script>

<style scoped>
.gray-card {
    background-color: var(--dark-gray);
    border-radius: var(--card-br);
    padding: 20px;
    color: var(--text-gray);
    column-gap: 15px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    height: 100%;
}

.textgroup-heading {
    color: black;
}

.text-group {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.textgroup-text {
    font-size: 14px;
    font-weight: 500;
}

.card-img-group {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 260px;
    max-height: 150px;
    width: 100%;
    height: 100%;
}

.card-img {
    max-width: 260px;
    max-height: 150px;
    aspect-ratio: auto;
}

@media screen and (max-width: 625px) {
    .gray-card {
        padding: 14px 16px;
        flex-direction: row;
    }

    .card-img-group {
        max-width: 88px;
        height: 88px;

    }

    .card-img {
        max-width: 88px;
        max-height: 88px;
    }

    .text-group {
        gap: 10px;
    }

    .textgroup-text {
        font-size: 12px;
    }
}
</style>
