<template>
    <div ref="startScene"
     class="media-group">
        <div ref="img"
         class="img-wrapper">
            <img class="img"
             :src="image"
             alt="media-image">
        </div>
        <div ref="text"
         class="text-group">
            <h4 class="textgroup-subheading">{{ subheading }}</h4>
            <h2 class="textgroup-heading">{{ heading }}</h2>
            <p class="textgroup-text">{{ text }}</p>
            <LearnMoreButton v-if="showButton"
             :text="this.$t('learnMore')"
             @click="openModal" />
        </div>
    </div>
    <Transition name="bounce">
        <ModalWindow v-if="showModal"
         @closeModal="closeModal"
         :title="modalTitle"
         :subtitle="modalSubtitle"
         :showUpButton="true">
            <slot></slot>
        </ModalWindow>
    </Transition>
</template>

<script>
import image from '@/assets/img/develop.jpeg'
import LearnMoreButton from '@/components/buttons/LearnMoreButton.vue'
import ModalWindow from '@/components/ModalWindow.vue'
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

export default {
    props: {
        imageMode: {
            type: String,
            default: "large"
        },
        showButton: {
            type: Boolean,
            default: true
        },
        modalTitle: {
            type: String,
            default: 'Some title'
        },
        modalSubtitle: {
            type: String,
            default: 'Some subheading'
        },
        image: {
            type: String,
            default: image
        },
        subheading: {
            type: String,
            default: 'Some subheading'
        },
        heading: {
            type: String,
            default: 'Power Plate'
        },
        text: {
            type: String,
            default: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quod.'
        }
    },
    components: {
        LearnMoreButton,
        ModalWindow,

    },
    data() {
        return {
            showModal: false,
            mode: {
                direction: 'row',
                align: 'center',
                flexBasisText: '50%',
                flexBasisImage: '40%',
            }
        }
    },
    mounted() {
        this.changeMode();
        this.animateScene();
    },
    methods: {
        animateScene() {
            const startScene = this.$refs.startScene;
            const img = this.$refs.img;
            const text = this.$refs.text;

            const tl = gsap.timeline({
                scrollTrigger: {
                    trigger: startScene,
                    start: 'top 60%',
                    end: 'bottom 60%',
                    toggleActions: 'play none none reverse',
                },
                defaults: {
                    ease: 'expo.out',
                    duration: 1,
                }
            });

            tl.from(img, {
                opacity: 0,
                scale: 0.5,
                x: -15,
                rotate: '-5deg',


            }, 'start')
                .from(text, {
                    opacity: 0,
                    x: 15,

                }, 'start')
        },
        changeMode() {
            switch (this.imageMode) {
                case "small":
                    this.mode = {
                        direction: 'row',
                        align: 'center',
                        flexBasisText: '50%',
                        flexBasisImage: '40%',
                    }
                    break;
                case "large":
                    this.mode = {
                        direction: 'column',
                        align: 'flex-start',
                        flexBasisText: 'unset',
                        flexBasisImage: 'unset',
                    }
                    break;
                case "small-reverse":
                    this.mode = {
                        direction: 'row-reverse',
                        align: 'center',
                        flexBasisText: '50%',
                        flexBasisImage: '40%',
                    }
                    break;
                default:
                    break;
            }
        },
        closeModal() {
            this.showModal = false;
        },
        openModal() {
            const body = document.querySelector('body');
            this.showModal = true;
            body.classList.add('menu-open');
        }
    }

}
</script>

<style scoped>
.media-group {
    display: flex;
    flex-direction: v-bind('mode.direction');
    gap: 1.25rem;
    justify-content: space-between;
    align-items: v-bind('mode.align');
    margin-top: 25px;
    margin-bottom: 4rem;
}

.text-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-basis: v-bind('mode.flexBasisText');
}

.textgroup-heading {
    margin-bottom: 15px;
    margin-top: 4px;
    font-size: 28px;
    line-height: 32px;
    font-weight: 700;
    max-width: 500px;
    letter-spacing: -.1px;
}

.textgroup-text {
    color: var(--text-gray);
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    max-width: 500px;
    margin-bottom: 20px;
}

.textgroup-subheading {
    color: var(--text-gray);
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    text-transform: uppercase;
}

.img-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-basis: v-bind('mode.flexBasisImage');
}

.img {
    max-width: 100%;
    border-radius: var(--card-br);
    width: 100%;
    aspect-ratio: 1/1;
    object-fit: cover;
}

@media screen and (max-width: 900px) {
    .text-group {
        flex-basis: 40%;
    }

    .img-wrapper {

        flex-basis: 50%;
    }


}


@media screen and (max-width: 660px) {
    .media-group {
        padding: 0;
        align-items: flex-start;
        flex-direction: column;
    }
}
</style>
