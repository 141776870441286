<template>
    <div id="slider">
        <transition name="slider"
         mode="out-in">
            <KeepAlive>
                <component :is="$options.slider[index]"
                 :key="index" />
            </KeepAlive>
        </transition>

        <div class="indicator"
         v-if="$options.slider.length > 1">
            <div class="indicator-line-wrap"
             @click="showSelectedSlide(index)"
             v-for="(component, index) in $options.slider"
             :key="index">
                <div class="indicator-line"
                 :class="{ 'active-line': this.index === index, 'indicator-white': this.index === 0 }"></div>
            </div>
        </div>
    </div>
</template>
<script>
// import FreeTrial from './FreeTrial.vue';
import GroupTrainings from './GroupTrainings.vue';
// import SummerPlan from './SummerPlan.vue';
// import NewYear from './NewYear.vue';
// import BlackFriday from './BlackFriday.vue';

export default {
    name: "AdvertSlider",
    data() {
        return {
            index: 0,
            interval: null,
        }
    },
    slider: [
        // FreeTrial,
        // SummerPlan,
        // BlackFriday,
        // NewYear,
        GroupTrainings
    ],
    components: {
        GroupTrainings
    },
    methods: {
        nextSlide() {
            this.index++;
            if (this.index > this.$options.slider.length - 1) {
                this.index = 0;
            }
        },
        prevSlide() {
            this.index--;
            if (this.index < 0) {
                this.index = this.$options.slider.length - 1;
            }
        },
        createInterval() {
            this.interval = setInterval(() => {
                this.nextSlide();
            }, 10000);
        },
        showSelectedSlide(index) {
            this.index = index;
            clearInterval(this.interval);
            this.interval = setInterval(() => {
                this.nextSlide();
            }, 10000);
            console.log(this.interval);
        }
    },
    created() {
        if (this.$options.slider.length > 1) {
            this.createInterval();
        }
    },
    beforeUnmount() {
        if (this.$options.slider.length > 1) {
            clearInterval(this.interval);
        }
    }


}
</script>
<style>
#slider {
    position: relative;
    overflow: hidden;
}

.indicator {
    position: absolute;
    z-index: 50;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    gap: 8px;
}

.indicator-line {
    width: 15px;
    height: 3px;
    border-radius: 10px;
    background-color: black;
    mix-blend-mode: difference;
    transition: all 0.3s ease;
    cursor: pointer;
}

.indicator-white {
    background-color: white;
}

.indicator-line-wrap {
    height: 20px;
    display: flex;
    align-items: center;
}

.active-line {
    width: 40px;
}
</style>
