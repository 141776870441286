<template>
    <div ref="cardsStart"
     class="cards-grid">
        <div class="gray-card-wrap"
         v-for="card in cardsContent"
         :key="card.id"
         ref="grayCard">
            <GrayPanel :title="card.title"
             :text="card.text"
             :image="card.image" />
        </div>
    </div>
</template>

<script>
import GrayPanel from '@/components/GrayPanel.vue'
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

export default {
    components: {
        GrayPanel,
    },
    props: {
        cardsContent: Array
    },
    data() {
        return {
            cardsNumber: 3,
        }
    },
    methods: {
        animateOnScroll() {
            const grayCard = this.$refs.grayCard;
            const cardsStart = this.$refs.cardsStart;
            gsap.registerPlugin(ScrollTrigger);
            const tl = gsap.timeline({
                scrollTrigger: {
                    trigger: cardsStart,
                    start: 'top 60%',
                    end: 'bottom 60%',
                    toggleActions: 'play none none reverse',
                    markers: false,
                },
                defaults: {
                    duration: 0.2,
                }
            });

            tl.from(grayCard, {
                opacity: 0,
                x: -15,
                stagger: 0.1,
            });

        }

    },
    mounted() {
        this.cardsNumber = this.cardsContent.length
        this.animateOnScroll();
    },
}
</script>

<style>
.gray-card-wrap {
    height: 100%;
}

/* grid */
.cards-grid {
    display: grid;
    grid-template-columns: repeat(v-bind(cardsNumber), 1fr);
    grid-template-rows: 1fr;
    column-gap: 22px;
    gap: 12px;
    margin: 25px 0;
}

@media screen and (max-width: 625px) {
    .cards-grid {
        grid-template-columns: repeat(1, 1fr);
    }
}
</style>
