export default {
  "pagenames": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "training": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training"])},
    "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])},
    "pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pricing"])},
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])}
  },
  "weekdays": {
    "mon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon"])},
    "tue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tue"])},
    "wed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wed"])},
    "thu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thu"])},
    "fri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fri"])},
    "sat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sat"])},
    "sun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sun"])}
  },
  "warnings": {
    "paymentDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From the 1st of September all clients will be getting invoices for the next months every 1st day of the month with the payment date of 7th day of each month."])}
  },
  "update112": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gift store is available"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New update"])},
    "block1": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get paid for going to the gym"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["35FIT is a first gym that pays for your trainings. New feature for our clients - gift store!"])}
    },
    "block2": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How to use?"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book a training via our website, come for a training and get 1 point for each visit*"])},
      "footnote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*All clients can receive store points if they have an active plan when points are added (every 1st day of month). The number of actual visits has to be within your package limit in order to get points. If the limit is exceeded, you will not get points."])}
    },
    "block3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collect points and pay up to 70% of services and products cost."])}
  },
  "update130": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New energy bars arrived!"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UPDATE"])},
    "block1": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have update the range of products in our shop"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From now on you can buy Harvest Republic energy bars via our shop using bonus points you have. One energy bar costs only 5 points. You can get up to two bars a month."])}
    },
    "block2": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How do I earn points?"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book your training in advance via dashboard, come and train. For every training done you will get a point.*"])},
      "footnote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*All clients can receive store points if they have an active plan when points are added (every 1st day of month). If you haven't had any active contract within two weeks, your points will be lost."])}
    },
    "block3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try and share your own experience!"])}
  },
  "newYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Happy <br>New Year!"])},
  "newYearText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We wish you a happy New Year and a lot of success in achieving your goals!"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
  "cookiehead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We use cookies"])},
  "cookie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["35FIT uses cookies for proper work and your best experience. You can check our <a href='/docs/GDPR.pdf'>privacy policy</a> to understand how we use cookies and collect information. By continuing using the website you agree with the use of this files"])},
  "newInv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A new invoice"])},
  "meta": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are AI-like gym! Sports club, personal training, fitness boutique studio, nutrition tests and many more."])},
    "training": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revolutionary AI gym. We have Milon, group Power Plate trainings, TRX, nutrition and cardio tests"])},
    "pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From €49/month instead of €49/training. Personal support connected to results with an AI-like gym"])}
  },
  "dashboard": {
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
    "bookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services and bookings"])},
    "contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract"])},
    "invoices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoices"])},
    "gifts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gifts"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
    "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View"])},
    "pages": {
      "gifts": {
        "earnTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Earn points"])},
        "earnSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GUIDE"])},
        "point": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["point"])},
        "points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["points"])},
        "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
        "euro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["euro"])},
        "backToRules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["back to rules"])},
        "howEarn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How to earn more points?"])},
        "readMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read more"])},
        "thisMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This month"])},
        "toEarn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["trainings to earn"])},
        "store": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Store"])},
        "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product details"])},
        "mandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MANDATORY"])},
        "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OPTIONAL"])},
        "additional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional points"])},
        "additionalText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will get 5 points for every Dynostics test and personal/group training you buy. Milon test gives you 2.5 points if you have bought it."])},
        "consistency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consistency"])},
        "discountText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Paying ", _interpolate(_named("price")), " points gives 70% discount off normal price. Discount is already calculated to the price you see"])},
        "discountTextFree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This item has 100% discount off normal price. Discount is already added"])},
        "modalText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Get ", _interpolate(_named("trainings")), " trainings (not more) to get a reward. Training is done when you have booked it and entered the club. Points are added every first day of the month."])},
        "noPackage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have no active package. To earn points you need to have an active contract. "])},
        "currTraining": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Your training count is ", _interpolate(_named("currentTraining")), " out of ", _interpolate(_named("trainings"))])},
        "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order history"])},
        "noOrders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No orders yet"])},
        "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order"])},
        "orderDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order date"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])}
      },
      "contract": {
        "last": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last contracts"])},
        "prolong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prolong"])},
        "discounts": {
          "workerDiscount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have a worker discount applied. Plan price is 17€/month"])},
          "testDiscount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have a discount on Dynostics (Performance) test. Test price is 25€"])}
        },
        "paymentModal": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a method"])},
          "enter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the code"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment"])},
          "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the code you have purchased from Stebby"])},
          "stebbyText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use Stebby code to pay for your training package"])},
          "payseraText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use Paysera to pay for your training package using Estonian bank account or any other supported method"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit code"])}
        },
        "currentContract": {
          "onHold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On hold"])},
          "currentContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current contract"])},
          "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duration"])},
          "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly"])},
          "concluded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concluded"])},
          "expires": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expires"])},
          "vacation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vacation"])},
          "months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["months"])},
          "noCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can't change or cancel your contract"])},
          "contactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us"])},
          "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change"])},
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
          "newContraсt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign a contract"])},
          "cancelModal": {
            "cancContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel contract"])},
            "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warning"])},
            "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback"])},
            "selectReason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a reason"])},
            "reasons": {
              "first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["35FIT is too far from home/work"])},
              "second": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plans and services are too expensive"])},
              "third": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal reasons not related to the club"])},
              "fourth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training type is not suitable for me"])},
              "fifth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I don't get enough feedback from trainer"])},
              "sixth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I don't see the result"])},
              "seventh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Issue is not listed/other"])}
            },
            "warnUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have to warn club at least 2 months before the day you want to cancel."])}
          }
        },
        "paymentWidget": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payments"])},
          "inDebt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your invoice for payment"])},
          "allOk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All is ok!"])},
          "due": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment due"])},
          "toPay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount to pay"])},
          "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next invoice"])},
          "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay"])},
          "prepay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prepay"])},
          "vacation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vacation"])},
          "modal": {
            "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your vacation period starts "])},
            "agreement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I agree to the terms and dates of vacation period"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract vacation"])},
            "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warning"])},
            "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["month"])},
            "months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["months"])},
            "short1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Just a little break"])},
            "short2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A long vacation (don't forget to train anyways!)"])},
            "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum vacation period is 2 month in a row during plan period. The vacation begins from the closest payment cycle."])},
            "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["During vacation you will be paying reduced price of <b>8 euros per month</b>. You will not be able to enter the club during that period."])},
            "selectPeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a period"])},
            "request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request vacation"])}
          },
          "noPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No future payments"])}
        }
      },
      "profile": {
        "doorCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Door code"])},
        "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])}
      },
      "noContract": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No active contract"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please, go to pricing to purchase a new contract"])},
        "toPricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to pricing"])}
      },
      "invoices": {
        "noInvoiceTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All is ok!"])},
        "noInvoiceText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currently you have no unpaid invoices"])},
        "invoiceTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have unpaid invoices"])},
        "invoiceText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seems like you have an unpaid invoice"])},
        "prepare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preparing the invoice..."])},
        "invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice #"])},
        "payBefore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay before: "])},
        "noInvoices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have no invoices yet"])},
        "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us"])},
        "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay now"])}
      },
      "booking": {
        "criticalError": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occured"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong. Try reloading the page"])}
        },
        "select": {
          "scheduled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scheduled"])},
          "canceled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canceled"])},
          "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed"])}
        },
        "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid services"])},
        "reserves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bookings"])},
        "noClasses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No classes scheduled"])},
        "noCanceled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No canceled classes"])},
        "noFinished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No finished classes"])},
        "modal": {
          "availableForActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bookings and additional services are available only if there is a active plan"])},
          "chooseTariff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select plan"])},
          "availableForWithoutDebt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserves and additional services are available only in the absence of unpaid bills"])},
          "notime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time isn't available for this day, please select another day"])},
          "lasts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lasts"])},
          "newBook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New booking"])},
          "errorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to create a reserve"])},
          "firstTrialText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a date for a first trial"])},
          "selectService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a service type"])},
          "selectAdditionalService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a service"])},
          "selectFreezePeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the start date of the freeze and the freeze period"])},
          "dateTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date and time"])},
          "selectDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a date"])},
          "selectTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a time"])},
          "orderService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order a service"])},
          "createBooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a reserve"])},
          "training": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["training"])},
          "milonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regular circle Milon training with AI equipment"])},
          "included": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["included in the contract"])},
          "minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minutes"])},
          "noWeekends": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not available on weekends"])},
          "noAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not available"])},
          "milonOther": {
            "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommend to take a towel"])},
            "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommend to take a bottle of water"])}
          },
          "tests": {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tests"])},
            "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Milon tests"])},
            "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["from €15"])}
          },
          "personal": {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal training"])},
            "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal training with a trainer"])},
            "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["€30/training"])}
          },
          "sgt": {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group training"])},
            "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group training with a trainer for 3 people"])},
            "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["€15/training"])}
          },
          "additional": {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional services"])},
            "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vitamin water, freezing"])}
          },
          "additionalServices": {
            "water": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vitamin drink (15 drinks) €7.95"])},
            "freeze": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freeze"])},
            "oneMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 month €8.00"])},
            "twoMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 months €16.00"])}
          }
        }
      }
    }
  },
  "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
  "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading"])},
  "header": {
    "menu": {
      "home": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
        "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return to the homepage"])}
      },
      "training": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training"])},
        "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["35 minutes 12 times a month"])}
      },
      "faq": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])},
        "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Answers for all common questions"])}
      },
      "pricing": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pricing"])},
        "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a suitable package"])}
      }
    },
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account"])},
    "lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])}
  },
  "footer": {
    "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacts"])},
    "legal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal"])},
    "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help"])},
    "social": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Social"])},
    "rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Club rules"])},
    "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
    "contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About us"])},
    "pricelist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price-list"])}
  },
  "learnMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn more"])},
  "home": {
    "trialTraining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span class='blue-highlight'>FREE TRIAL</span><br>TRAINING"])},
    "moto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI gym, <br> designed <br> for you."])},
    "firstSection": {
      "h1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simple. Safe. Effective."])},
      "h2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fitness boutique studio"])},
      "card1": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individual approach"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Face-to-face support and help thanks to automated system."])}
      },
      "card2": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["High tech equipment"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI-like machines adjust in seconds to your size, needs and goal."])}
      },
      "card3": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time saving "])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whole-body circuit training takes only 35 min for great results."])}
      }
    },
    "secondSection": {
      "h1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suits every goal"])},
      "h2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Any level. Any target."])},
      "card1": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muscle building"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training load with elevated weight."])}
      },
      "card2": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pain-free training"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regime targets the root causes underlying physical discomfort."])}
      },
      "card3": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight reduction"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Combination of endurance and moderate strength training."])}
      },
      "card4": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firming"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Targeted strength workout for lean body shape."])}
      }
    },
    "thirdSection": {
      "h1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training with a smile"])},
      "h2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Circle training"])},
      "card1": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training equipment"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All the machines you need for the whole body workout are here."])}
      },
      "card2": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No waiting time"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Never wait for your turn to equipment. “No queue” design gym."])}
      },
      "card3": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Circuit formula"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don’t think - just do it. Train according to ready programm."])}
      }
    },
    "mediaContent": {
      "h1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unique benefits"])},
      "h2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max effectiviness"])},
      "powerPlate": {
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Power Plate"])},
        "subheading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whole body vibration"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The unique micro vibrations activate more muscles, burn more calories, improve circulation, and assist muscle recovery."])},
        "modalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Power Plate"])},
        "modalSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whole body vibration"])},
        "mediaInner": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stretching with vibration"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vibration platforms are availible to use for stretching after workout to ease out muscle sourness helping to recover faster."])}
        },
        "mediaInner2": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training with vibration"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training using vibration platforms has such benefits as increasing bone density, burning up to twice as much fat."])}
        },
        "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register at 35FIT"])}
      },
      "trugge": {
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vitamin water"])},
        "subheading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lifefuel"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get 37% of daily dose of important vitamins in just one sugar free drink during your workout."])},
        "modalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vitamin water"])},
        "modalSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lifefuel"])},
        "mediaInner": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flavours"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over 30 flavours that change every month. 5 flavours to choose from each month."])}
        },
        "mediaInner2": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Composition"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vitamins B5, B6, B12, E, niacin, folic acid of German production. Only 3 kcal per one drink."])}
        },
        "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register at 35FIT"])}
      },
      "tests": {
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Progress tracking tests"])},
        "subheading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Milon"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test your flexibility, balance and reaction speed. All in one Milon test, which takes only 5 minutes. The test results as well as the dynamics are available instantly on the screen."])},
        "modalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tests"])},
        "modalSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dynostics"])},
        "mediaInner": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test your metabolism"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Takes 5 minutes for analysis which tells your current metabolic situation and how much to eat according to your goal."])}
        },
        "mediaInner2": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test your performance"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In our studio your heart will be connected with cardio equipment. Take a test for ideal bpm for fat burn or better stamina."])}
        },
        "mediaInner3": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test your mobility"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In our studio your heart will be connected with cardio equipment. Take a test for ideal bpm for fat burn or better stamina."])}
        },
        "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register at 35FIT"])}
      }
    }
  },
  "training": {
    "intro": {
      "h1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training"])},
      "h2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revolutionary AI fitness for better results"])}
    },
    "segmented": {
      "circle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Circle training"])},
      "tests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tests"])},
      "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group training"])}
    },
    "circle": {
      "section1": {
        "h1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Circle training"])},
        "h2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["That takes 35 minutes"])},
        "card1": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Circuit training"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Machines are placed in a circle, perfect mix of power and cardio."])}
        },
        "card2": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All under control"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heart, progress and even workout motion control are always visible."])}
        },
        "card3": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI-type equipment"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Machines remember you and make adjustsments to fit you."])}
        }
      },
      "section2": {
        "h1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suits every goal"])},
        "h2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Any level. Any target"])},
        "card1": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eccentric"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In negative type training muscles are utilized in an ideal way."])}
        },
        "card2": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adaptive"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resistance depends on speed. Ideal for therapy and competitive sports."])}
        },
        "card3": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Isokinetic"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatically adjusts the weight in real time. Good for hypertrophy."])}
        }
      },
      "section3": {
        "h1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instant feedback"])},
        "h2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training and progress"])},
        "card1": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workout statistics"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A cloud-based system connects your phone with equipment."])}
        },
        "card2": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smart training control"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weights are elevated based on your adaptivness to exercise."])}
        },
        "card3": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See your dynamics"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instant access to data which can be seen at a glance."])}
        }
      },
      "section4": {
        "h1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equipment in studio"])},
        "h2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Best approach"])},
        "media": {
          "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Milon"])},
          "subheading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pioneer and innovator of digital training. With more than 1000 clubs around the globe 35FIT is the one and only Milon studio in Estonia."])},
          "modalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training with a smile"])},
          "modalSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simple and effective"])},
          "inner": {
            "first": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solution to many issues"])},
              "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do not know what to do in a regular sports club? Can not reach wanted results? 35FIT studio is the solution to all of your concerns. "])}
            },
            "second": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interactive display"])},
              "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Display provides instant feedback during training which helps do exercise correctly, allows instant personal feedback."])}
            },
            "third": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When 35 minutes is enough"])},
              "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Due to intensive workload and proper power settings 35 minutes for 2-3 times a week is perfect for being fit and healthy."])}
            }
          }
        }
      },
      "modal": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simple training"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Easy and effective"])},
        "first": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Circuit training"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whole body strength training including cardio is provided by machines placed in a circle for no queue design. Do not waste time, just train!"])}
        },
        "second": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Control"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keep up with dynamics via app, control your workout motion through the interactive display. Your heart is also controlled and adjusted starting from SUPER FLEX package."])}
        },
        "third": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI - type equipment"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use a personal braclet to login in and start your training. It will provide suitable weights and range of motion. The system recommends loads and heart progression."])}
        }
      }
    },
    "tests": {
      "section1": {
        "h1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Result is a sum of right training and nutrition"])},
        "t1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For better results we always recommend to test and track your nutrition, performance and dynamics."])},
        "cardsContent": {
          "card1": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dynostics Nutrition"])},
            "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trainings with individual nutrition plan is the key to your success."])}
          },
          "card2": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dynostics Performance"])},
            "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect your heart with equipemnt for better results."])}
          },
          "card3": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fitness and Flexibility test"])},
            "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quick and fun way to fixate and observe your fitness dynamics"])}
          }
        }
      },
      "section2": {
        "h1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instant feedback"])},
        "h2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training and progress"])},
        "cardsContent": {
          "card1": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individual control"])},
            "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trainer will overview your results and help to set and follow your strategy."])}
          },
          "card2": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quick and fun"])},
            "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metabolism test takes 5 min, Performance - 15 min, Fitness - 5 min."])}
          },
          "card3": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Always at hand"])},
            "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access your results and observe anytime anywhere"])}
          }
        }
      },
      "modal": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training with a smile"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Easy and effective"])},
        "first": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dynostics Nutrition"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["70% of your goal depends on balanced nutrition strategy. Test together with trainer’s consultation will give you full understanding on your dietry. "])}
        },
        "second": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dynostics Performance"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After the test, we transfer your individual heart data to system so it will automatically adjust for fat burn. You will get to know your fitness level, VO2 max and regeneration."])}
        },
        "third": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fitness and Flexibility test"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nothing but fun. Quick test for checking and fixating your flexibility, speed of reaction, jumping power and balance. After several test your trainer will observe dynamics."])}
        }
      }
    },
    "groupTraining": {
      "section1": {
        "h1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Challenging. Fun."])},
        "h2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Small group trainings"])},
        "card1": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guided workout"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 to 8 week program with a trainers in a group of 4 (max)."])}
        },
        "card2": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equipment"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TRX, BootyBuilder, and Plate are used during workout."])}
        },
        "card3": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time saving"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Highly intensive goal orientated training in less time."])}
        }
      },
      "section2": {
        "h1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fulfill your goal"])},
        "h2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training programs"])},
        "card1": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booty"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Be fully focused on activating and shaping your glutes and tights."])}
        },
        "card2": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strong"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Improve muscle tone. Best for increasing your power."])}
        },
        "card3": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zen"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Class targets body and mind. Incorporates yoga and pilates."])}
        }
      },
      "section3": {
        "h1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unique benefits"])},
        "h2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vibration training"])},
        "card1": {
          "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Burn fat quicker"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Micro vibrations force you to work out harder burning fat quicker."])}
        },
        "card2": {
          "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Improve flexibility"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whole body vibrations have a significant effect on flexibility."])}
        },
        "card3": {
          "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Improve bone strength"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vibrations make your bones work harder which makes them strong."])}
        }
      },
      "media": {
        "h1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Different approach"])},
        "h2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session equipment"])},
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Power Plate"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vibrating platform triggers immediate contractions of working and stabilizing muscles improving balance and strength."])},
        "modalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Science based approach"])},
        "modalSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get results quicker"])},
        "inner": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max effectiveness less time"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["55 min of training can fit into 25 min by training with vibration platform, more muscles are activated during workout."])},
          "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional benefits"])},
          "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Research has shown that training with vibration can also strenghten bones and impove circulation."])}
        },
        "mediaContent": {
          "first": {
            "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booty builder"])},
            "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hip thrust machine gives users the optimal range of motion and movement for targeting the gluteus maximus."])}
          },
          "second": {
            "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TRX"])},
            "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TRX uses body weight exercises to develop strength, balance, flexibility and core stability."])}
          }
        },
        "modal": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Small group trainings"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training programs"])},
          "first": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booty"])},
            "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We promise, your glutes will burn with this insane workout which includes best equipment and execises for muscle activation. "])}
          },
          "second": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strong"])},
            "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muscle gain is achieved by extended strength exercises on the vibration platform. Vibration activates more muscles, burns more fat."])}
          },
          "third": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zen"])},
            "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perfect programm to relax and pay attention to your body and body-mind connetion, includes massage on the vibration platform."])}
          }
        }
      }
    }
  },
  "pricing": {
    "intro": {
      "h1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pricing"])},
      "h2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a package that suits your needs"])}
    },
    "other": {
      "modal": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract conditions"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overview"])}
      },
      "free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free"])},
      "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mo."])},
      "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More"])},
      "less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Less"])},
      "subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribe"])},
      "planChange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The paid period and services will not be refunded if you change your plan"])},
      "pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the Price-list"])},
      "acknowledge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I acknowledge that I have read and accept the conditions of the"])},
      "payWithStebby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment via Stebby"])},
      "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
      "booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["booking"])},
      "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["order"])},
      "additional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional"])},
      "groups": {
        "circleTraining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Circle training"])},
        "tests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tests"])},
        "groupTraining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal Training"])}
      },
      "segments": {
        "plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plans"])},
        "tests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tests"])},
        "personal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal"])}
      }
    }
  },
  "login": {
    "passwordReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password reset link has been sent to your email if it exists in our database"])},
    "resetPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your email"])},
    "passwordPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your password"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
    "validEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid email address"])},
    "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot password?"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in to Account"])},
    "resetTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset password"])},
    "serverError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A server error"])},
    "unableLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to login"])},
    "emptyCredentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login data cannot be empty"])},
    "emailPasswordIncorrect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email or password is incorrect"])}
  },
  "registration": {
    "introText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hello there! 👋🏻"])},
    "introText2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let's get to know each other?"])},
    "changableText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register at 35FIT"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surname"])},
    "idcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID code"])},
    "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender"])},
    "prefLang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preferred languages"])},
    "selectLang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select languages"])},
    "footnote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select all the languages you know, so we can select a trainer for your first trial"])},
    "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Male"])},
    "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Female"])},
    "dob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of birth"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing address"])},
    "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacts"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "confirmPass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm password"])},
    "dataAgree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I agree to <a target='_blank' class='green-text' href='/docs/GDPR.pdf'>the use and processing of my personal data</a>"])},
    "rulesAgree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I agree to the club's <a target='_blank' class='green-text' href='/docs/Club_Rules.pdf'>rules</a>"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
    "emailSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have sent you an email. Check it to finish the registration"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
    "errorCreating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error creating the account"])},
    "emptyTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empty rows"])},
    "emptyText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill in all the fields"])},
    "stageText": {
      "first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go on, "])},
      "second": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You're almost there!"])},
      "third": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secure your account"])},
      "fourth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finish!"])}
    },
    "passErrors": {
      "doNotMatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwords do not match"])},
      "doNotMatchText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please, check your password"])},
      "tooShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password is too short"])},
      "tooShortText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use at least 8 symbols including numbers and letters"])},
      "legalConsent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal consent"])},
      "legalConsentText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please, confirm your data processing and rules"])},
      "invalidMail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid email"])},
      "invalidMailText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please, check your email"])}
    },
    "trialNotRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trial training is not required"])},
    "iHaveNoIdCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I have no ID code"])}
  },
  "advert": {
    "blackfriday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span>Black</span><span>friday</span>"])},
    "bfpoints": {
      "first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FREE GROUP TRAININGS"])},
      "second": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FREE PROTEIN SHAKE ON A TRIAL TRAINING"])},
      "third": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FREE MILON TEST"])},
      "fourth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LEARN MORE FROM YOUR TRAINER"])}
    },
    "trialpromo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trial training for free! Until 28.08"])},
    "trialAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trial training is available from 17.04"])},
    "standard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register at 35FIT!"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
    "summerPromo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span class='intro-gradient' ref='summer'>SUMMER</span><br>CAMPAIGN"])},
    "summerf1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only €35/month"])},
    "summerf2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full day access"])},
    "summerf3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available until 31.07"])},
    "summerBottomPanel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SUMMER package for €35/month"])},
    "buyNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy now"])},
    "test": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bring a friend to get a Dynostics test!"])},
    "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share"])},
    "regCampaign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span class='green-highlight'>FREE</span><br>REGISTRATION!"])},
    "regCampaignText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make a contract during your trial"])},
    "regCampaignText2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't pay for the contract fee"])},
    "regCampaignText3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save €30"])},
    "bookGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book a <br>group training"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A trial personal training<br>session for only €15"])},
    "descriptionFirst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trial training 40 min (Milon training simulator,PowerPlate)"])},
    "descriptionSecond": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 Milon Fitness tests (free)"])},
    "descriptionThird": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Determining training goals"])},
    "descriptionFourth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upon immediate contract sign, registration fee 0 euros"])}
  },
  "faq": {
    "pagename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find answer to most common questions"])},
    "contract": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract"])},
      "first": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How to get a free test for bringing a friend?"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your friend just has to mention your name during signing the contract or tell us by email. We will contact with you regarding the test immediately"])}
      },
      "second": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Which packages do you offer?"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currently we have 6 packages. All of them have different advantages. We do recommend to take ALL under CONTROL and Connnected to result plans, since you will have an opportunity to get all the advantages and reach your goal faster. You can compare plans on the <a href='/pricing' class='green-text'>pricing</a> page"])}
      },
      "third": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When does the plan start to work?"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In case of the trial package, it starts to be valid since the time you registered on the website. The usual plan becomes valid upon conclusion on the website. The conclusion considered to be done when you agreed with the conditions and pressed button 'Buy' on the pricing page."])}
      },
      "fourth": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you sell a one-time ticket?"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We do sell personal trainings in a small hall. The price of the one training is on pricing page + 8 euros for one-time entrance to the club. In case you want to try our trainings, there is always a trial plan"])}
      },
      "fifth": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What is the bracelet deposit?"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To start your trainings in the club we provide you with the personal key bracelet, so you would be able to use the door lock and the training machines. The bracelet is a property of the club. We take the deposit in case you loose a bracelet. When your contract is finished and you have no intention to continue it, we return your bracelet deposit to your bank account back."])}
      },
      "sixth": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can I pay via Stebby?"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, we do have Stebby profile and you can pay for services there. The link is <a class='green-text' href='https://app.stebby.eu/pos/35fit.com'>here</a>"])}
      },
      "seventh": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How and when the bills are sent?"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will be getting invoices 10 days before the payment date on the email you register your account with. If you haven't received an email with the bill, please, check a spam folder. You can also find invoices in your personal account. Log in - Invoices. Invoices may be paid via Bank transfer, website or Stebby. We do not accept cash or card payments. The invoice has to be paid even if you are not visiting the gym, because the contract is made on the principle of subscription fee."])}
      },
      "eight": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can I terminate the contract before the end date?"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have two types of contracts: mandatory with stricted end date and general. General contracts are: ALL under CONTROL, Connected to result, Super Flex, Flex. Mandatory contract with strict end date are SMART and IDEAL. General contracts can be canceled anytime with no reason, but you have to warn club two months before you want to cancel a contract. Mandatory contract can't be canceled and you have to pay a monthly subscription for the whole period of the contract validity time (even if you are not visiting the gym). The exception for both types of contract is injury that prevents doing sports (in this case you will need to provide a valid confirmation from a hospital etc.). A contract will be terinated immediately"])}
      },
      "ninth": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can I get a vacation from trainings?"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can get up to two months of vacation depending on the chosen plan. The vacation is provided for the full month, so you can't have f.e. only 10 days or 2 weeks of vacation. Every month of vacation you have to pay 8 euros instead of your monthly plan payment"])}
      },
      "tenth": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What if I haven't paid the bill?'"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you haven't paid your bill on time, the door will be closed automatically and you won't be able to enter the club until the debt is paid. If you haven't paid for a long time, the machines may be also off for you. If you don't pay for 2 months, the contract will be closed automatically."])}
      }
    },
    "training": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training"])},
      "first": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What do I need to take to the gym?"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For a comfortable workout, you should take in advance: sportswear, changeable shoes, a sports bottle, a towel"])}
      },
      "second": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How to get into the club?"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use your personal bracelet or your personal pin-code. If you forget it, just log in to your account, go to Dashboard - Profile and it will be there. Note, that code or bracelet won't work if you have debts."])}
      },
      "third": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is there a trainer in the club?"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The trainer explains everything on the first training and then follows you and tracks your results via our system. A trainer is usually present in the evening on weekdays, but if you need a trainer consultation you can always contact us by email, and we will put trainer for you."])}
      },
      "fourth": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How much time the training lasts?"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The training is designed for approximately 35 minutes + time to change clothes/take a shower"])}
      },
      "fifth": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do I need to book the training every time?"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We do recommend to book a time on the website. The amount of people at the same time in the gym is restricted for your comfort and safety. Booking helps us to predict the amount of people in the gym, so we can put trainer for you"])}
      },
      "sixth": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How can I track my results?"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have a <a class='green-text' href='https://www.milonme.com/login'>Milon Me app</a>. Just login with the credentials you provided during your first training."])}
      },
      "seventh": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What if I lost a heart rate belt or a bracelet?"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In case you lost a bracelet, the 8 euros deposit won't be returned to you. If you lost a heart rate belt, you have to pay 35 euros fee"])}
      }
    },
    "other": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])},
      "first": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you have a pool?"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No, we do not have pool/bath. We only have showers."])}
      },
      "second": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you have a parking lot?"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regretfully, we do not have a dedicated parking area. During weekdays you can leave your car at the nearest Selver parking. On the weekends you can park your car at neighborhood parking lots for free."])}
      },
      "third": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you have a water spot?"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We do have a water machine. Most training plans include water in the price, but if you have a plan without it, you may want to buy a subscription separately - it costs 7.95 euros."])}
      }
    },
    "gallery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gallery"])},
    "about": {
      "legal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal"])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
      "openHours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open hours"])},
      "monFri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon-Fri"])},
      "satSun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sat-Sun"])},
      "stateHolidays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State holidays"])},
      "office": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Office"])}
    }
  }
}