<template>
    <transition name="bounce">
        <button @click="$emit('clicked')" v-if="show">
            <img :src="plus" class="icon" alt="plus">
            {{ text }}
        </button>
    </transition>
</template>

<script>
import plus from '@/assets/svg/plus-thick.svg'

export default {
    name: 'NewButton',
    props: {
        text: {
            type: String,
            default: 'New'
        }
    },
    data() {
        return {
            plus,
            show: false
        }
    },
    mounted() {
        setTimeout(() => {
            this.show = true
        }, 300)
    },
    unmounted() {
        this.show = false
    }
}
</script>

<style scoped>
.icon {
    width: 15px;
    filter: invert();
}

button {
    background-color: var(--light-green);
    display: flex;
    align-items: center;
    gap: 5px;
    font-family: var(--font);
    color: white;
    border: none;
    border-radius: var(--br);
    padding: 7px 15px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    margin: 15px 0;
}

button:hover {
    background-color: var(--lighter-green);
}

button:active {
    scale: 0.98;
}
</style>
