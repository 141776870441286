<template>
    <div class="class">
        <div class="class-info">
            <div class="left">
                <img class="icon"
                 :src="icon"
                 alt="clock">
                <div class="text-group">
                    <h4 class="class-heading">{{ classInfo.name }}</h4>
                    <p class="class-text">{{ classInfo.date }} at {{ classInfo.time }}</p>
                </div>
            </div>
            <div class="right">
                <img class="edit"
                 @click="$emit('delete')"
                 :src="cross"
                 alt="edit"
                 v-if="modifyOn">
            </div>
        </div>
    </div>
</template>
<script>
import clock from '@/assets/svg/clock.svg'
import xmark from '@/assets/svg/xmark.svg'
import check from '@/assets/svg/check.svg'
import cross from '@/assets/svg/cross.svg'

export default {
    name: "ScheduledClass",
    props: {
        classInfo: {
            type: Object,
            required: true
        },
        mode: {
            type: String,
            required: true,
            default: 'scheduled'
        },
    },
    components: {

    },
    data() {
        return {
            icon: null,
            cross,
            xmark,
            check,
            clock,
            modifyOn: true,
            dateOptions: ["Tue, 1 Nov. 2022", "Wed, 2 Nov. 2022", "Thu, 3 Nov. 2022", "Fri, 4 Nov. 2022", "Sat, 5 Nov. 2022", "Sun, 6 Nov. 2022"],
            timeOptions: ["10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00"]
        }
    },
    mounted() {
        switch (this.mode) {
            case 'scheduled':
                this.icon = this.clock;
                break;
            case 'finished':
                this.icon = this.check;
                this.modifyOn = false
                break;
            case 'canceled':
                this.icon = this.xmark;
                this.modifyOn = false
                break;
        }
    }
}
</script>

<style scoped>
.menu-select {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.class-heading {
    max-width: 200px;
}

.class-menu {
    margin-top: 10px;
}

.edit {
    height: 15px;
    cursor: pointer;
}

.icon {
    width: 15px;
    height: 15px;
}

.class-text {
    font-size: 12px;
}

.menu-items {
    display: flex;
    gap: 10px;
    margin-top: 10px;
}

.destructive {
    background: var(--red) !important;
}

.menu-items button {
    background: var(--light-green);
    width: 100%;
    color: white;
    padding: 8px;
    font-family: var(--font);
    border-radius: var(--br);
    font-size: 12px;
    font-weight: 600;
    transition: all 0.2s ease-in-out;
}

.menu-items button:hover {
    opacity: 0.9;
}

.menu-items button:active {
    opacity: 0.8;
    scale: 0.95;
}

.right,
.left {
    display: flex;
    align-items: center;
    gap: 10px;
}

.class-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.class {
    padding: 15px 10px;
    border-bottom: 1px solid var(--dark-gray);
}

.class:first-of-type {
    border-top: 1px solid var(--dark-gray);
}

.text-group {
    display: flex;
    flex-direction: column;
    -webkit-user-select: none;
    user-select: none;
}
</style>
