<template>
    <div class="menu-item"
     :style='{
         backgroundImage: "linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(255, 255, 255, 0) 59.66%), url(" + photo + ")",
     }'>
        <div class="text-group">
            <h3>{{ heading }}</h3>
            <p>{{ text }}</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HeaderItem',
    props: {
        heading: String,
        text: String,
        photo: String,
    },
    computed: {

    },

}

</script>

<style scoped>
.menu-item {
    margin-right: 10px;
    margin-left: 10px;
    border-radius: 5px;
    height: var(--menu-item-height);
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 20px;
    padding: 20px;
    line-height: 105.9%;
    background-color: var(--gray);
    color: white;
    transition: all 0.2s ease-in-out;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.menu-item:hover {
    background-color: var(--dark-gray);
    transform: scale(0.98);
}

.text-group {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 5px;
}

.text-group h3 {
    font-size: 20px;
    line-height: 105.9%;

}

.text-group p {
    font-weight: 500;
    font-size: 12px;
    line-height: 105.9%;

}

.menu-item-hide {
    animation: disappear 0.5s ease-in-out;
}


@keyframes appear {
    from {
        opacity: 0;
        transform: translateX(35%);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes disappear {
    from {
        opacity: 1;
        transform: translateX(0%);
    }

    to {
        opacity: 0;
        transform: translateX(35%);
    }
}




@media screen and (max-width: 660px) {

    .menu-item-hide {
        opacity: 0;
        animation: none;
    }

    @keyframes appear {
        from {
            opacity: 0;
            transform: translateY(-35%);
        }

        to {
            opacity: 1;
            transform: translateY(0);
        }
    }


}
</style>
