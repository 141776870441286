<template>
    <section>
        <div class="training-feature">
            <h2>{{ $t('training.circle.section1.h1') }}</h2>
            <h2 class="gray">{{ $t('training.circle.section1.h2') }}</h2>
            <CardsGrid :cardsContent="cardsContent" />
            <LearnMoreButton class="center-btn"
             :text="$t('learnMore')"
             @click="openModal" />
        </div>
        <div class="training-feature">
            <h2>{{ $t('training.circle.section2.h1') }}</h2>
            <h2 class="gray">{{ $t('training.circle.section2.h2') }}</h2>
            <CardsGrid :cardsContent="cardsContent2" />
        </div>

        <div class="training-feature">
            <h2>{{ $t('training.circle.section3.h1') }}</h2>
            <h2 class="gray">{{ $t('training.circle.section3.h2') }}</h2>
            <GrayCardUnited :content="content" />
        </div>

        <div class="training-feature">
            <h2>{{ $t('training.circle.section4.h1') }}</h2>
            <h2 class="gray">{{ $t('training.circle.section4.h2') }}</h2>
            <MediaContent :imageMode="equipmentStudio.mode"
             :heading="equipmentStudio.heading"
             :image="equipmentStudio.image"
             :text="equipmentStudio.text"
             :modalTitle="equipmentStudio.modalTitle"
             :subheading="equipmentStudio.subheading"
             :modalSubtitle="equipmentStudio.modalSubtitle">

                <MediaContentInner :image="media1"
                 :title="$t('training.circle.section4.media.inner.first.title')"
                 :text="$t('training.circle.section4.media.inner.first.text')" />

                <MediaContentInner :image="media2"
                 :title="$t('training.circle.section4.media.inner.second.title')"
                 :text="$t('training.circle.section4.media.inner.second.text')" />

                <MediaContentInner :image="media3"
                 :title="$t('training.circle.section4.media.inner.third.title')"
                 :text="$t('training.circle.section4.media.inner.third.text')" />

                <LearnMoreButton @click="
                toRegister"
                 :text="$t('home.mediaContent.powerPlate.register')"
                 v-if="loggedOut" />

            </MediaContent>

            <transition name="bounce">
                <ModalWindow v-if="showModal"
                 :title="$t('training.circle.modal.title')"
                 :subtitle="$t('training.circle.modal.subtitle')"
                 @closeModal="showModal = !showModal">

                    <MediaContentInner :image="media4"
                     :title="$t('training.circle.modal.first.title')"
                     :text="$t('training.circle.modal.first.text')" />

                    <MediaContentInner :image="media5"
                     :title="$t('training.circle.modal.second.title')"
                     :text="$t('training.circle.modal.second.text')" />

                    <MediaContentInner :image="media6"
                     :title="$t('training.circle.modal.third.title')"
                     :text="$t('training.circle.modal.third.text')" />

                </ModalWindow>
            </transition>
        </div>
    </section>
</template>
<script>
import CardsGrid from '@/components/CardsGrid.vue';
import card1 from '@/assets/img/home/card1.webp';
import card2 from '@/assets/img/training/milon/card3.png';
import card3 from '@/assets/img/training/milon/card2.png';
import LearnMoreButton from '../buttons/LearnMoreButton.vue';
import GrayCardUnited from '@/components/GrayCardUnited.vue';
import MediaContent from '@/components/MediaContent.vue';
import MediaContentInner from '@/components/MediaContentInner.vue';
import card4 from '@/assets/img/home/card4.webp';
import card6 from '@/assets/img/home/card6.webp';
import card7 from '@/assets/img/home/card7.webp';
import cycling from '@/assets/svg/bar-red.svg'
import clockXmark from '@/assets/svg/dynamics.svg'
import repeat from '@/assets/svg/point.svg'
import trainingMedia from '@/assets/img/training/training-media.png';
import ModalWindow from '../ModalWindow.vue';
import media1 from '@/assets/img/training/milon/media1.webp';
import media2 from '@/assets/img/training/milon/media2.webp';
import media3 from '@/assets/img/training/milon/media3.webp';
import media4 from '@/assets/img/training/milon/media4.webp';
import media5 from '@/assets/img/training/milon/media5.webp';
import media6 from '@/assets/img/training/milon/media6.webp';

export default {
    name: 'CircleTraining',
    components: {
        CardsGrid,
        LearnMoreButton,
        GrayCardUnited,
        MediaContent,
        MediaContentInner,
        ModalWindow
    },
    data() {
        return {
            showModal: false,
            media1,
            media2,
            media3,
            media4,
            media5,
            media6,
            content: [
                {
                    id: 1,
                    icon: cycling,
                    heading: this.$t('training.circle.section3.card1.title'),
                    text: this.$t('training.circle.section3.card1.text'),
                },
                {
                    id: 2,
                    icon: clockXmark,
                    heading: this.$t('training.circle.section3.card2.title'),
                    text: this.$t('training.circle.section3.card2.text'),
                },
                {
                    id: 3,
                    icon: repeat,
                    heading: this.$t('training.circle.section3.card3.title'),
                    text: this.$t('training.circle.section3.card3.text'),
                }
            ],
            cardsContent: [{
                id: 1,
                title: this.$t('training.circle.section1.card1.title'),
                text: this.$t('training.circle.section1.card1.text'),
                image: card1
            },
            {
                id: 2,
                title: this.$t('training.circle.section1.card2.title'),
                text: this.$t('training.circle.section1.card2.text'),
                image: card2
            },
            {
                id: 3,
                title: this.$t('training.circle.section1.card3.title'),
                text: this.$t('training.circle.section1.card3.text'),
                image: card3
            }],
            cardsContent2: [{
                id: 1,
                title: this.$t('training.circle.section2.card1.title'),
                text: this.$t('training.circle.section2.card1.text'),
                image: card4
            },
            {
                id: 2,
                title: this.$t('training.circle.section2.card2.title'),
                text: this.$t('training.circle.section2.card2.text'),
                image: card6
            },
            {
                id: 3,
                title: this.$t('training.circle.section2.card3.title'),
                text: this.$t('training.circle.section2.card3.text'),
                image: card7
            },
            ],
            equipmentStudio: {
                mode: 'small',
                heading: this.$t('training.circle.section4.media.heading'),
                image: trainingMedia,
                subheading: this.$t('training.circle.section4.media.subheading'),
                text: this.$t('training.circle.section4.media.text'),
                modalTitle: this.$t('training.circle.section4.media.modalTitle'),
                modalSubtitle: this.$t('training.circle.section4.media.modalSubtitle')
            },
        }
    },
    methods: {
        openModal() {
            const body = document.querySelector('body');
            this.showModal = !this.showModal;
            body.classList.add('menu-open');
        }
    },

}
</script>
<style>

</style>
