<template>
    <div class="main">
        <img :src="payment"
         alt="Payment succeed">
        <h3>Payment failed</h3>
        <p>
            It was not possible to complete the payment. Please try again later.
        </p>
        <router-link to="/dashboard">
            <MainButton class="marg-top"
             text="Go to the dashboard"
             :widthRestrict="true" />
        </router-link>
    </div>
</template>
<script>
import payment from '@/assets/svg/payment.svg'
import MainButton from '@/components/buttons/MainButton.vue';


export default {
    name: "PaymentFailedView",
    data() {
        return {
            payment
        };
    },
    components: { MainButton },
    mounted() {
        if (!document.referrer.startsWith('https://bank.paysera.com')) {
            this.$router.push('/dashboard');
        }
    }
}
</script>
<style scoped>
.main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    height: 100vh;
    min-height: 500px;
    padding: 20px;
}

a {
    width: 100%;
}

p {
    max-width: 450px;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
}

img {
    width: 100%;
    max-width: 350px;
}

@media screen and (max-width: 420px) {

    img {
        max-width: 300px;
    }

}
</style>
