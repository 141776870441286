<template>
    <div class="media-content-info">
        <img v-if="image"
         class="media-content-info-image"
         :src="image"
         alt="image media content">
        <h2 v-if="title">{{ title }}</h2>
        <p v-if="text">
            {{ text }}
        </p>
    </div>
</template>

<script>

export default {
    props: {
        image: {
            type: String
        },
        title: {
            type: String
        },
        text: {
            type: String
        },
        aspectRatio: {
            type: String,
            default: '1/1'
        }
    },

}
</script>

<style scoped>
.media-content-info {
    margin: 30px 0;
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    align-items: flex-start;
    justify-content: flex-start;
}

.media-content-info-image {
    max-width: 100%;
    border-radius: var(--card-br);
    max-height: 100%;
    width: 100%;
    height: 100%;
    min-width: 100%;
    max-width: 100%;
    max-height: 400px;
    aspect-ratio: v-bind(aspectRatio);
    object-fit: cover;
    
}

.media-content-info p {
    color: var(--text-gray);
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
}
</style>
