<template>
    <transition name="bounce"
     mode="out-in">
        <div class="full-page"
         v-if="loader || ticketLoader">
            <LoaderView />
        </div>
        <div v-else-if="invoices.length != 0"
         class="invoices-page">
            <div class="invoices-list">
                <div class="invoices-health">
                    <div class="inv-health-wrapper">

                        <h2 v-if="!status.paid">€{{ status.debt }}</h2>
                        <div>
                            <h3 class="text-warning"
                             v-if="newInvoice(currentDate, this.ticket) && status.paid">
                                {{ $t('newInv') }}
                            </h3>
                            <h3 v-else
                             class="paid-indicator">{{ status.title }}</h3>
                            <p class="invoice-status-text">{{ status.text }}</p>
                        </div>
                    </div>
                    <MainButton class="button-max red-btn"
                     v-if="(!status.paid) || newInvoice(currentDate, this.ticket)"
                     :text="$t('dashboard.pages.invoices.pay')"
                     @click="pay()" />
                    <a class="small-link invoices-contact-pos"
                     href="mailto:info@35fit.com?subject=More info about my 35FIT debt">{{ $t('dashboard.pages.invoices.contact') }}</a>
                </div>
                <transition name="bounce">
                    <InputNotify v-if="showNotify.show"
                     class="marg-top full-width"
                     :icon="showNotify.icon"
                     :text="showNotify.text" />
                </transition>
                <ListRow v-for="invoice in invoices"
                 :key="invoice.id"
                 :title="$t('dashboard.pages.invoices.invoice') + invoice.invoice"
                 :icon="icon"
                 :date="$t('dashboard.pages.invoices.payBefore') + invoice.due"
                 @clicked="downloadInvoice(invoice)" />
            </div>
        </div>
        <NoInfoPlaceholder v-else
         :img="noInvoices"
         :text="$t('dashboard.pages.invoices.noInvoices')" />
    </transition>
</template>
<script>
import icon from '@/assets/svg/invoice.svg'
import info from '@/assets/svg/info.svg'
import xmark from '@/assets/svg/xmark.svg'
import noInvoices from '@/assets/svg/no-invoices.svg'
import ListRow from './ListRow.vue';
import MainButton from '../buttons/MainButton.vue';
import LoaderView from '../buttons/LoaderView.vue';
import InputNotify from '../InputNotify.vue';
import NoInfoPlaceholder from './NoInfoPlaceholder.vue';

export default {
    name: 'InvoicesPage',

    data() {
        return {
            icon,
            info,
            xmark,
            ticket: null,
            loader: true,
            ticketLoader: true,
            currentDate: this.currentDateString(),
            showNotify: {
                show: false,
                text: '',
                icon: ''
            },
            noInvoices,
            invoices: [],
            status: {
                paid: true,
                title: this.$t('dashboard.pages.invoices.noInvoiceTitle'),
                text: this.$t('dashboard.pages.invoices.noInvoiceText'),
                debt: 0
            },

        }
    },
    components: {
        ListRow,
        MainButton,
        LoaderView,
        InputNotify,
        NoInfoPlaceholder
    },
    methods: {
        getTicket() {
            fetch('https://app.35fit.com/api/users/ticket', {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                },
            })
                .then(res => res.json())
                .then(data => {
                    if (data.success) {

                        this.ticket = data.ticket
                        this.ticketLoader = false

                        this.manageStatus()
                        return
                    }
                })
                .catch(err => {
                    console.log(err)
                })

        },
        newInvoice(current, ticket = null) {
            if (ticket == null) {
                return false
            }
            const date = ticket.next_pay.split('.');
            const day = date[0];
            const month = date[1];
            const year = date[2];
            const newDate = year + '-' + month + '-' + day;

            if (typeof ticket.next_payment_price != 'string') {
                return false
            }

            if (new Date(current).getTime() >= new Date(newDate).getTime()) {
                return true
            }
            return false
        },
        currentDateString() {
            const date = new Date();
            // add 10 days to the current date
            date.setDate(date.getDate() + 10);
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();
            // if day or month is less than 10, add 0 to the beginning
            const dayString = day < 10 ? '0' + day : day;
            const monthString = month < 10 ? '0' + month : month;
            return year + '-' + monthString + '-' + dayString;
        },
        pay() {
            this.$emit('pay')
        },
        getInvoices() {
            fetch('https://app.35fit.com/api/users/invoices', {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                },
            })
                .then(res => {
                    if (res.status !== 200) {
                        throw new Error(res.status);
                    }
                    return res.json();
                })
                .then(data => {
                    if (data.success) {
                        this.invoices = data.invoices
                        this.loader = false
                        return
                    }
                })
                .catch(err => {
                    console.log(err)
                    if (err.message == 401) {
                        localStorage.removeItem('token');
                        this.$router.push('/login');
                    }
                })
        },
        downloadInvoice(invoice) {
            this.showNotify = {
                show: true,
                text: this.$t('dashboard.pages.invoices.prepare'),
                icon: this.info
            };
            fetch('https://app.35fit.com/api/users/invoices/download', {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(invoice)
            })
                .then(response => response.blob())
                .then(blob => {
                    var url = window.URL.createObjectURL(blob);
                    var a = document.createElement('a');
                    a.href = url;
                    a.download = "35FIT OÜ Arve nr " + invoice.invoice + ".pdf";
                    this.showNotify.show = false;
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                })
                .catch(err => {
                    this.showNotify = {
                        show: true,
                        text: err.message,
                        icon: this.xmark
                    };

                    setTimeout(() => {
                        this.showNotify = false;
                    }, 3000);
                });


        },
        manageStatus() {
            if (this.ticket == null) {
                this.status.paid = true
                this.status.title = this.$t('dashboard.pages.invoices.noInvoiceTitle')
                this.status.text = this.$t('dashboard.pages.invoices.noInvoiceText')
                return
            }

            if (this.ticket.debt <= 0) {
                this.status.paid = true
                this.status.title = this.$t('dashboard.pages.invoices.noInvoiceTitle')
                this.status.text = this.$t('dashboard.pages.invoices.noInvoiceText')
            } else {
                this.status.paid = false
                this.status.debt = this.ticket.debt
                this.status.title = this.$t('dashboard.pages.invoices.invoiceTitle')
                this.status.text = this.$t('dashboard.pages.invoices.invoiceText')

            }
        }
    },
    mounted() {
        this.getTicket()
        this.getInvoices()
    }
}
</script>
<style>
.invoices-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 35px;
    min-height: 70vh;
}

.invoices-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.invoices-health {
    padding: 20px;
    display: flex;
    position: relative;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    gap: 15px;
    background-color: var(--gray);
    border-radius: var(--card-br);
}

.invoices-contact-pos {
    position: absolute;
    top: 20px;
    right: 20px;
}

.invoice-status-text {
    font-size: 14px;
    font-weight: 500;
}

.button-max {
    max-width: 200px !important;
    margin: 0 !important;
}

@media screen and (max-width: 500px) {
    .invoices-health {
        align-items: center;
    }

    .button-max {
        max-width: 100% !important;
    }

}

.inv-health-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: 5px;
    justify-content: center;
}

.paid-indicator {
    color: v-bind('status.paid ? "var(--light-green)" : "var(--red)"');
    margin-bottom: 3px;
}
</style>
