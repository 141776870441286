<template>
    <div class="faq-item">
        <div @click="open"
         class="question-preview">
            <p>{{ this.title }}</p>
            <img class="plus"
             :data-id="id"
             :src="plus"
             alt="open" />
        </div>
        <Transition name="bounce">
            <p v-if="opened"
             v-html="this.text"
             class="faq-text">
            </p>
        </Transition>
    </div>
</template>

<script>
import plus from '@/assets/svg/plus.svg';

export default {
    data() {
        return {
            plus,
            opened: false,
            id: null
        }
    },
    props: {
        title: {
            type: String,
            required: true
        },
        text: {
            type: String,
            required: true
        }
    },
    methods: {
        open() {
            // select plus with data-id
            const plus = document.querySelector(`[data-id="${this.id}"]`);
            plus.classList.toggle('rotate');
            this.opened = !this.opened;
        }
    },
    mounted() {
        this.id = Math.random().toString(32).slice(2);
    }

}

</script>

<style scoped>
.faq-item {
    width: 100%;
    border-bottom: 1px solid var(--divider);
}

.faq-text {
    color: var(--text-gray);
    font-size: 14px;
    line-height: 24px;
    font-weight: 500;
}

.plus {
    transition: transform 0.3s ease;
}

.rotate {
    transform: rotate(45deg);
}

.question-preview {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 15px;
    padding: 20px 0;
}

.faq-text {
    padding-bottom: 20px;
}

.question-preview p {
    max-width: 90%;
}
</style>
