<template>
    <div class="current-contract">
        <div class="current-contract-heading">
            <h5>{{ $t('dashboard.pages.contract.currentContract.currentContract') }}</h5>
            <h2 v-if="!ticket.status_freeze">{{ ticket.name }}</h2>
            <h2 class="text-warning"
             v-else>{{ $t('dashboard.pages.contract.currentContract.onHold') }}</h2>
        </div>
        <transition name="bounce"
         mode="out-in">
            <ul class="current-contract-list"
             v-if="!this.description">
                <li>
                    {{ $t('dashboard.pages.contract.currentContract.duration') }}:
                    {{ this.selectedTemplate.duration }}
                </li>
                <li v-for="feature in this.selectedTemplate.included"
                 :key="feature">{{ feature }}</li>
            </ul>
            <div v-else
             class="current-contract-info">
                <div class="contract-info-row">
                    <p>{{ $t('dashboard.pages.contract.currentContract.monthly') }}</p>
                    <p>€{{ this.selectedTemplate.monthly }}</p>
                </div>
                <div class="contract-info-row">
                    <p>{{ $t('dashboard.pages.contract.currentContract.concluded') }}</p>
                    <p>{{ this.ticket.started.split('T')[0].split('-').reverse().join('.') }}</p>
                </div>
                <div class="contract-info-row">
                    <p>{{ $t('dashboard.pages.contract.currentContract.expires') }}</p>
                    <p>{{ this.ticket.finished.split('T')[0].split('-').reverse().join('.') }}</p>
                </div>
                <div class="contract-info-row">
                    <p>{{ $t('dashboard.pages.contract.currentContract.vacation') }}</p>
                    <p>{{ this.selectedTemplate.vacation }} {{ $t('dashboard.pages.contract.currentContract.months') }}
                    </p>
                </div>

                <div v-if="(this.ticket.ticket_ticket_id == 632355 ||
                    this.ticket.ticket_ticket_id == 632360) || 2502995"
                 class="contract-buttons-group">
                    <h5 class="top-marg">{{ $t('dashboard.pages.contract.currentContract.noCancel') }}</h5>
                    <a class="small-link"
                     href="mailto:info@35fit.com">
                        {{ $t('dashboard.pages.contract.currentContract.contactUs') }}
                    </a>
                </div>
                <div v-else
                 class="contract-buttons-group">
                    <MainButton v-if="this.ticket.ticket_ticket_id != 613481"
                     class="top-marg"
                     @click="this.router.push('/pricing#grid')"
                     :text="$t('dashboard.pages.contract.currentContract.change')" />
                    <button v-if="this.ticket.ticket_ticket_id != 613481"
                     class="cancel-subscription"
                     @click="openModal">{{ $t('dashboard.pages.contract.currentContract.cancel') }}</button>
                </div>
            </div>
        </transition>
        <div v-if="false"
         class="contract-buttons-group">
            <MainButton class="top-marg"
             @click="this.router.push('/pricing#grid')"
             :text="$t('dashboard.pages.contract.currentContract.newContraсt')" />
        </div>
        <img @click="this.description = !this.description"
         :src="info"
         class="contract-info-icon"
         alt="info">

        <Transition name="bounce"
         mode="out-in">
            <ModalWindow v-if="showModal"
             :title="$t('dashboard.pages.contract.currentContract.cancelModal.cancContract')"
             :subtitle="$t('dashboard.pages.contract.currentContract.cancelModal.warning')"
             @closeModal="this.showModal = !this.showModal;">
                <Transition name="bounce"
                 mode="out-in">
                    <LoaderView v-if="loader" />
                    <div v-else
                     class="modal-confirm-plan">
                        <img class="new-contract-img"
                         :src="cancel_contract"
                         alt="contract">
                        <h3>{{ $t('dashboard.pages.contract.currentContract.cancelModal.feedback') }}</h3>
                        <SelectTag :label="$t('dashboard.pages.contract.currentContract.cancelModal.selectReason')"
                         :options="[
                             $t('dashboard.pages.contract.currentContract.cancelModal.reasons.first'),
                             $t('dashboard.pages.contract.currentContract.cancelModal.reasons.second'),
                             $t('dashboard.pages.contract.currentContract.cancelModal.reasons.third'),
                             $t('dashboard.pages.contract.currentContract.cancelModal.reasons.fourth'),
                             $t('dashboard.pages.contract.currentContract.cancelModal.reasons.fifth'),
                             $t('dashboard.pages.contract.currentContract.cancelModal.reasons.sixth'),
                             $t('dashboard.pages.contract.currentContract.cancelModal.reasons.seventh'),
                         ]"
                         mode="text"
                         :placeholder="$t('dashboard.pages.contract.currentContract.cancelModal.selectReason')"
                         @select="(selected) => this.reason = selected" />
                        <transition name="bounce">
                            <InputNotify v-if="warning.show"
                             :icon="warning.icon"
                             :text="warning.text"
                             class="full-width" />
                        </transition>
                        <InputNotify :icon="info"
                         :text="$t('dashboard.pages.contract.currentContract.cancelModal.warnUs')"
                         class="full-width" />
                        <MainButton v-if="(this.reason !== null)"
                         :widthRestrict="true"
                         text="Cancel the contract"
                         @click="cancelContract" />
                    </div>
                </Transition>
            </ModalWindow>
        </Transition>


        <NotifyWarning v-if="success.show"
         title="Success"
         :icon="success.icon"
         :text="success.text"
         color="green"
         @close="success.show = false" />

    </div>
</template>
<script>
import info from '@/assets/svg/info.svg'
import MainButton from '../buttons/MainButton.vue';
import ModalWindow from '../ModalWindow.vue';
import plans from '@/resources/plans.json';
import router from '@/router';
import cancel_contract from '@/assets/svg/cancel_contract.svg'
import SelectTag from '../selectors/SelectTag.vue';
import InputNotify from '../InputNotify.vue';
import xmark from '@/assets/svg/xmark.svg'
import check from '@/assets/svg/check.svg'
import NotifyWarning from '../warnings/NotifyWarning.vue';
import LoaderView from '../buttons/LoaderView.vue';


export default {
    name: 'CurrentContract',
    props: {
        ticket: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            cancel_contract,
            xmark,
            loader: false,
            check,
            reason: null,
            router,
            info,
            description: false,
            paid: true,
            plans,
            json: {},
            showModal: false,
            selectedTemplate: null,
            warning: {
                icon: null,
                text: null,
                show: false
            },
            success: {
                icon: null,
                text: null,
                show: false
            }
        }
    },
    components: {
        MainButton,
        ModalWindow,
        SelectTag,
        LoaderView,
        InputNotify,
        NotifyWarning
    },
    beforeMount() {
        switch (this.$i18n.locale) {
            case 'en':
                this.json = this.plans.plans.en
                break;
            case 'et':
                this.json = this.plans.plans.et
                break;
            case 'ru':
                this.json = this.plans.plans.ru
                break;
            default:
                this.json = this.plans.plans.en
                break;
        }

        this.selectedTemplate = this.json.plans.find(plan => plan.id == this.ticket.ticket_ticket_id) || {
            included: [],
            duration: 0,
            monthly: 0,
            vacation: null
        };
    },
    methods: {
        openModal() {
            this.reason = null;
            const body = document.querySelector('body');
            this.showModal = !this.showModal;
            body.classList.add('menu-open');
        },
        cancelContract() {

            this.loader = true;
            fetch('https://app.35fit.com/api/users/ticket', {
                method: 'DELETE',
                headers: {
                    'Authorization': 'Bearer ' +
                        localStorage.getItem('token'),
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    reason: this.reason
                })
            })
                .then(res => {
                    if (res.status !== 200) {
                        throw new Error(res.status);
                    }
                    return res.json();
                })
                .then(data => {
                    if (data.success) {
                        this.openModal();
                        this.success = {
                            icon: this.check,
                            text: data.message,
                            show: true
                        }

                        return
                    }

                    this.warning = {
                        icon: this.xmark,
                        text: data.message,
                        show: true
                    }
                    this.loader = false;

                })
                .catch(err => {
                    console.log(err)
                    if (err.message == 401) {
                        this.openModal();
                        localStorage.removeItem('token');
                        this.$router.push('/login');
                    }

                    this.loader = false;
                    this.warning = {
                        icon: this.xmark,
                        text: err.message,
                        show: true
                    }
                })
        }
    }
}
</script>
<style>
.top-marg {
    margin-top: 20px !important;
}

.contract-info-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-weight: 500;
    color: var(--text-gray);
    border-bottom: 1px solid var(--dark-gray);
    padding: 10px 0;
}

.contract-info-row p {
    font-size: 12px;
}

.contract-info-row:first-of-type {
    border-top: 1px solid var(--dark-gray);
}

.contract-buttons-group {
    display: flex;
    gap: 10px;
    flex-direction: column;
    align-items: center;
    width: 100%;
    text-align: center;
}

.current-contract-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.current-contract {
    padding: 20px;
    width: 100%;
    border-radius: var(--card-br);
    background-color: var(--gray);
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
}


.contract-info-icon {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.current-contract-list {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding-left: 20px;
    font-size: 12px;
    color: var(--text-gray);
    font-weight: 500;
}

.current-contract h2 {
    text-transform: uppercase;
    color: var(--light-green);
}

.current-contract h5 {
    font-size: 12px;
    text-transform: uppercase;
    color: var(--text-gray);
}
</style>
