<template>
    <main class="training">

        <section class="page-start">
            <h1 class="page-name">{{ $t('training.intro.h1') }}.
                <span class="gray">{{ $t('training.intro.h2') }}</span>
            </h1>
        </section>

        <div class="training-cover"
         :style="{ 'background-image': 'url(' + cover + ')' }">
        </div>

        <div class="training">
            <section class="section">
                <SegmentedSelect :options="options"
                 @selected="(id) => this.tab = id" />

                <transition name="bounce"
                 mode="out-in">
                    <CircleTraining v-if="(tab == 0)" />
                    <TestsTraining v-else-if="(tab == 1)" />
                    <GroupTraining v-else />
                </transition>


            </section>

        </div>

    </main>
</template>
<script>
import cover from '@/assets/img/training/cover.webp';
import SegmentedSelect from '@/components/selectors/SegmentedSelect.vue';
import CircleTraining from '@/components/training/CircleTraining.vue';
import TestsTraining from '@/components/training/TestsTraining.vue';
import GroupTraining from '@/components/training/GroupTraining.vue';
import { useMeta } from 'vue-meta'

export default {
    name: 'TrainingView',
    components: {
        SegmentedSelect,
        CircleTraining,
        TestsTraining,
        GroupTraining
    },
    beforeMount() {
        useMeta({
            title: this.$t('pagenames.training') + " | AI gym",
            link: [
                { rel: 'canonical', href: 'https://35fit.com/training' },
            ],
            htmlAttrs: {
                lang: this.$i18n.locale,
            },
            meta: [
                { property: "og:title", content: "35FIT | " + this.$t('pagenames.training') + " | AI gym" },
                { property: "og:description", content: this.$t('meta.training') },
                { property: "og:url", content: "https://35fit.com/training" },
            ],
            description: this.$t('meta.training')
        })
    },
    data() {
        return {

            vh: window.visualViewport.height + 'px',
            cover,
            options: [{
                name: this.$t('training.segmented.circle'),
                id: 0
            },
            {
                name: this.$t('training.segmented.tests'),
                id: 1
            },
            {
                name: this.$t('training.segmented.group'),
                id: 2
            }],
            tab: 0
        }
    }
}
</script>
<style>
.training {
    display: block;
    background-color: white;
    width: 100%;
}

.training-cover {
    margin-top: 46px;
    height: v-bind(vh);
    width: 100%;
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
}
</style>
