<template>
    <div class="multiple-select-wrap">
        <Transition name="bounce">
            <div v-if="selected.length > 0"
             class="selected-items">
                <p v-for="lang in selected"
                 :key="lang"
                 class="item"
                 @click="deleteSelected(lang)">{{ lang }}</p>
            </div>
        </Transition>
        <div @click="toggle"
         class="multiple-selector">
            <p class="label">{{ label }}</p>
            <Transition name="bounce">
                <div v-if="show"
                 class="options">
                    <ul>
                        <li v-for="option in options"
                         :key="option"
                         @click="select(option)">
                            {{ option }}
                        </li>
                    </ul>
                </div>
            </Transition>
        </div>
        <p class="footnote">{{ footnote }}</p>
    </div>
</template>
<script>
export default {
    name: "MultipleSelect",
    props: {
        options: {
            type: Array,
            required: true,
        },
        label: {
            type: String,
            default: "Select languages"
        },
        footnote: {
            type: String,
            default: "Select multiple"
        },
        initSelected: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            selected: [],
            show: false,
            br: "var(--br)",
            id: Math.random().toString(36).substr(2, 9)
        }
    },
    methods: {
        toggle() {
            this.show = !this.show
            this.br = this.show ? "0px" : "var(--br)"
        },
        select(value) {
            if (!this.selected.includes(value)) {
                this.selected.push(value)
                this.$emit("select", this.selected)
            }
        },
        deleteSelected(value) {
            this.selected = this.selected.filter((item) => item !== value)
            this.$emit("select", this.selected)
        },
    },
    mounted() {
        this.selected = this.initSelected;
    }
}
</script>
<style scoped>
.multiple-select-wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.item {
    font-size: 13.333px;
    font-weight: 500;
    padding: 10px 20px;
    border-radius: var(--br);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    width: fit-content;
    background-color: var(--gray);
    user-select: none;
}

.footnote {
    font-size: 11px;
    line-height: 15px;
    color: var(--text-gray);
    padding-left: 10px;
}

.selected-items {
    display: flex;
    gap: 10px;
    overflow-x: scroll;
    max-width: inherit;
}

.selected-items::-webkit-scrollbar {
    display: none;
}

.item:hover {
    background-color: var(--dark-gray);
}

.label {
    font-size: 13.333px;
    font-weight: 500;
    width: 100%;
    height: 100%;
    color: var(--text-gray);
}

ul {
    list-style: none;
    display: flex;
    flex-direction: column;
}

ul li {
    padding: 20px 20px;
    transition: all 0.2s ease-in-out;
    font-size: 13.333px;
    font-weight: 500;
}

.multiple-selector:hover,
ul li:hover {
    background-color: var(--gray);
}

.options {
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-height: 170px;
    overflow-y: auto;
    border-bottom-left-radius: var(--br);
    border-bottom-right-radius: var(--br);
    left: 0;
    z-index: 50;
    width: 100%;
    top: 55px;
    background-color: rgb(247, 247, 247);
}

.multiple-selector {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    background-color: var(--dark-gray);
    border: none;
    border-top-left-radius: var(--br);
    border-top-right-radius: var(--br);
    border-bottom-left-radius: v-bind("br");
    border-bottom-right-radius: v-bind("br");
    padding: 20px 20px;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
}
</style>
