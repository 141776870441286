<template>
    <section>
        <div class="training-feature">
            <div class="tests-media">
                <div class="column">
                    <h2 class="short-heading">{{ $t('training.tests.section1.h1') }}</h2>
                    <p>
                        {{ $t('training.tests.section1.t1') }}
                    </p>
                </div>
                <img :src="testsTitle"
                 class="img"
                 alt="Testing a client">
            </div>

            <CardsGrid :cardsContent="cardsContent" />
            <LearnMoreButton class="center-btn"
             :text="$t('learnMore')"
             @click="openModal" />

        </div>

        <div class="training-feature">
            <h2>{{ $t('training.tests.section2.h1') }}</h2>
            <h2 class="gray">{{ $t('training.tests.section2.h2') }}</h2>
            <GrayCardUnited :content="content" />
        </div>

        <Transition name="bounce"
         mode="out-in">
            <ModalWindow v-if="showModal"
             :title="$t('training.tests.modal.title')"
             :subtitle="$t('training.tests.modal.subtitle')"
             @closeModal="this.showModal = !this.showModal">
                <MediaContentInner :image="tests1"
                 :title="$t('training.tests.modal.first.title')"
                 :text="$t('training.tests.modal.first.text')" />

                <MediaContentInner :image="tests2"
                 :title="$t('training.tests.modal.second.title')"
                 :text="$t('training.tests.modal.second.text')" />

                <MediaContentInner :image="tests3"
                 :title="$t('training.tests.modal.third.title')"
                 :text="$t('training.tests.modal.third.text')" />

            </ModalWindow>
        </Transition>

    </section>
</template>
<script>
import CardsGrid from '@/components/CardsGrid.vue';
import LearnMoreButton from '../buttons/LearnMoreButton.vue';
import card1 from '@/assets/img/training/tests/nutrition.png';
import card2 from '@/assets/img/training/tests/performance.png';
import card3 from '@/assets/img/training/tests/flexibility.png';
import testsTitle from '@/assets/img/training/tests/dynomain.webp'
import tests1 from '@/assets/img/home/tests1.webp'
import tests2 from '@/assets/img/home/tests2.webp'
import tests3 from '@/assets/img/home/tests3.webp'
import GrayCardUnited from '@/components/GrayCardUnited.vue';
import user from '@/assets/svg/user-red.svg'
import smile from '@/assets/svg/smile.svg'
import point from '@/assets/svg/handy.svg'
import ModalWindow from '../ModalWindow.vue';
import MediaContentInner from '../MediaContentInner.vue';

export default {
    name: "TestsTraining",
    components: {
        CardsGrid,
        LearnMoreButton,
        GrayCardUnited,
        ModalWindow,
        MediaContentInner
    },
    data() {
        return {
            tests2,
            tests1,
            tests3,
            testsTitle,
            showModal: false,
            cardsContent: [{
                id: 1,
                title: this.$t('training.tests.section1.cardsContent.card1.title'),
                text: this.$t('training.tests.section1.cardsContent.card1.text'),
                image: card1
            },
            {
                id: 2,
                title: this.$t('training.tests.section1.cardsContent.card2.title'),
                text: this.$t('training.tests.section1.cardsContent.card2.text'),
                image: card2
            },
            {
                id: 3,
                title: this.$t('training.tests.section1.cardsContent.card3.title'),
                text: this.$t('training.tests.section1.cardsContent.card3.text'),
                image: card3
            }],
            content: [
                {
                    id: 1,
                    icon: user,
                    heading: this.$t('training.tests.section2.cardsContent.card1.title'),
                    text: this.$t('training.tests.section2.cardsContent.card1.text'),
                },
                {
                    id: 2,
                    icon: smile,
                    heading: this.$t('training.tests.section2.cardsContent.card2.title'),
                    text: this.$t('training.tests.section2.cardsContent.card2.text'),
                },
                {
                    id: 3,
                    icon: point,
                    heading: this.$t('training.tests.section2.cardsContent.card3.title'),
                    text: this.$t('training.tests.section2.cardsContent.card3.text'),
                }
            ],
        }
    },
    methods: {
        openModal() {
            const body = document.querySelector('body');
            this.showModal = !this.showModal;
            body.classList.add('menu-open');
        }
    }


}
</script>
<style scoped>
.tests-media {
    display: flex;
    align-items: center;
    gap: 50px;
    margin: 25px 0;
}

.column {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.tests-media p {
    color: var(--text-gray);
    font-weight: 500;
    margin: 10px 0;
    line-height: 20px;
}

.img {
    max-width: 370px;
    border-radius: var(--card-br);
    width: 100%;
    aspect-ratio: 1/1;
    object-fit: cover;
}

.short-heading {
    max-width: 310px;
}

@media screen and (max-width: 835px) {
    .tests-media {
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
    }


    .img {
        max-width: 100%;
        aspect-ratio: unset;
        max-height: 375px;
    }
}

@media screen and (max-width: 500px) {
    .img {
        aspect-ratio: 1/1;
    }
}
</style>
