<template>
    <div class="tests-page">
        <div class="tests-rows-group">
            <TestsRow @clicked="resultScreen = !resultScreen"
             title="Dynostics Metabolism"
             :icon="testresult"
             date="11.11.2011" />
            <TestsRow @clicked="resultScreen = !resultScreen"
             title="Dynostics Metabolism"
             :icon="testresult"
             date="11.11.2011" />
            <TestsRow @clicked="resultScreen = !resultScreen"
             title="Dynostics Metabolism"
             :icon="testresult"
             date="11.11.2011" />
        </div>
        <NewButton class="add-button"
         @clicked="toSchedule"
         text="Book a test" />
        <Transition name="bounce">
            <ModalWindow v-if="resultScreen"
             @closeModal="resultScreen = !resultScreen"
             title="Your test results"
             subtitle="report">
                <TestResults />
            </ModalWindow>
        </Transition>
    </div>
</template>

<script>
import TestsRow from './ListRow.vue';
import NewButton from '@/components/buttons/NewButton.vue';
import TestResults from './TestResults.vue';

import ModalWindow from '../ModalWindow.vue';
import testresult from '@/assets/svg/testresult.svg'

export default {
    name: 'TestsPage',
    components: {
        TestsRow,
        NewButton,
        ModalWindow,
        TestResults
    },
    data() {
        return {
            bookScreen: false,
            resultScreen: false,
            testresult
        }
    },
    methods: {
        toSchedule() {
            this.$emit('toSchedule');
        }
    }

}
</script>

<style>
.tests-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.tests-rows-group {
    width: 100%;
}
</style>
