<template>
    <div class="store-grid">
        <div v-for="item in orderedStore"
         :key="item.id"
         class="store-item">
            <img class="store-img"
             :src="baseUrl + item.image"
             :alt="item.name">
            <div class="item-text-group">
                <h4>{{ item.name }}</h4>
                <p>{{ item.price }} {{ $t('dashboard.pages.gifts.points') }}</p>
                <button @click="showItem(item)"
                 class="item-button">{{ $t('dashboard.view') }}</button>
            </div>
        </div>
        <transition name="bounce">
            <ModalWindow v-if="(activeItem != null)"
             :title="$t('dashboard.pages.gifts.details')"
             :subtitle="$t('dashboard.pages.gifts.store')"
             @closeModal="
                 activeItem = null">
                <div class="store-item-inner">
                    <img class="store-img-inner"
                     :src="baseUrl + activeItem.image"
                     alt="store img">
                    <div class="store-item-headings">
                        <h2>{{ activeItem.name }}</h2>
                        <p class="item-price">{{ activeItem.price }} {{ $t('dashboard.pages.gifts.points') }} &
                            {{ activeItem.price_eur / 100 }} {{ $t('dashboard.pages.gifts.euro') }}
                        </p>
                    </div>
                    <p>{{ localizedDesc(activeItem) }}</p>
                    <div class="store-item-grid">
                        <div v-for="feature in items.find(good => activeItem.id === good.id).features"
                         :key="feature.id"
                         class="store-item-grid-column">
                            <img class="store-icon"
                             :src="feature.icon"
                             alt="feature icon">
                            <h4>{{ feature.name }}</h4>
                            <p>{{ feature.description }}</p>
                        </div>
                    </div>
                    <p v-if="activeItem.id != 3 && activeItem.id != 4 && activeItem.id != 8 && activeItem.id != 9">
                        {{ $t('dashboard.pages.gifts.discountText', { price: activeItem.price }) }}
                    </p>
                    <p v-else>
                        {{ $t('dashboard.pages.gifts.discountTextFree') }}
                    </p>
                    <MainButton :text="$t('dashboard.pages.invoices.pay')"
                     :widthRestrict="true"
                     @click="buy(activeItem.id)" />
                </div>
            </ModalWindow>
        </transition>
    </div>
</template>
<script>
import storeTest from '../../../assets/img/store/store-test.png'
import bandsShop from '../../../assets/img/store/bands-shop.png'
import proteinShop from '../../../assets/img/store/protein-shop.png'
import ModalWindow from '@/components/ModalWindow.vue'
import MainButton from '@/components/buttons/MainButton.vue'
import handy from '@/assets/svg/handy.svg'
import clockXmark from '@/assets/svg/clock-xmark.svg'
import heart from '@/assets/svg/heart.svg'
import check from '@/assets/svg/check.svg'
import personal from '@/assets/svg/personal.svg'
import mass from '@/assets/svg/mass.svg'
import health from '@/assets/svg/health.svg'
import barRed from '@/assets/svg/bar-red.svg'
import point from '@/assets/svg/point.svg'

export default {
    name: "StoreGrid",
    props: {
        store: {
            type: Array,
            default: () => []
        },
        baseUrl: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            orderedStore: [],
            storeTest,
            bandsShop,
            proteinShop,
            activeItem: null,
            items: [
                {
                    id: 1,
                    features: [
                        {
                            icon: handy,
                            name: "Exact result",
                            description: "Make own nutrition plan based on exact data you get."
                        },
                        {
                            icon: clockXmark,
                            name: "Fast",
                            description: "This test takes only 5 minutes and you get your results instantly. Get access anytime, anywhere."
                        },
                    ]
                },
                {
                    id: 2,
                    features: [
                        {
                            icon: heart,
                            name: "Exact result",
                            description: "Make own training plan based on exact data you get. Loose more fat and gain more muscle."
                        },
                        {
                            icon: health,
                            name: "Useful",
                            description: "Make this test at least once a quarter to track your progress. The more you train the better results you get."
                        },
                    ]
                },
                {
                    id: 4,
                    features: [
                        {
                            icon: mass,
                            name: "Mass",
                            description: "60 gramms of energy bar"
                        },
                        {
                            icon: health,
                            name: "Organic",
                            description: "Organic structure makes this product extremely useful for gaining energy before or after a training."
                        },
                    ]
                },
                {
                    id: 9,
                    features: [
                        {
                            icon: mass,
                            name: "Mass",
                            description: "60 gramms of energy bar"
                        },
                        {
                            icon: health,
                            name: "Organic",
                            description: "Organic structure makes this product extremely useful for gaining energy before or after a training."
                        },
                    ]
                },
                {
                    id: 8,
                    features: [
                        {
                            icon: mass,
                            name: "Mass",
                            description: "60 gramms of energy bar"
                        },
                        {
                            icon: health,
                            name: "Organic",
                            description: "Organic structure makes this product extremely useful for gaining energy before or after a training."
                        },
                    ]
                },
                {
                    id: 3,
                    features: [
                        {
                            icon: mass,
                            name: "Mass",
                            description: "60 gramms of energy bar"
                        },
                        {
                            icon: health,
                            name: "Organic",
                            description: "Organic structure makes this product extremely useful for gaining energy before or after a training."
                        },
                    ]
                },
                {
                    id: 6,
                    features: [
                        {
                            icon: personal,
                            name: "Anywhere",
                            description: "Train at home, in the gym, in the park, on the beach - anywhere you want. You can use them with Booty Builder too."
                        },
                        {
                            icon: check,
                            name: "Different levels",
                            description: "There are number of bands with different resistance up to 20 kg."
                        },
                    ]
                },
                {
                    id: 7,
                    features: [
                        {
                            icon: mass,
                            name: "Mass",
                            description: "300 gramms of whey protein"
                        },
                        {
                            icon: health,
                            name: "Organic",
                            description: "Organic structure makes this product extremely useful for any type of training whether it's a weight loss or muscle building"
                        },
                    ]
                },
                {
                    id: 5,
                    features: [
                        {
                            icon: barRed,
                            name: "Track progress",
                            description: "Make this test at least once a quarter to track your progress. The more you train the better results you get."
                        },
                        {
                            icon: point,
                            name: "Result is recorded",
                            description: "Compare results with your previous tests and get access to it anytime you want."
                        },
                    ]
                }
            ]
        };
    },
    methods: {
        showItem(item) {
            const body = document.querySelector('body');
            this.activeItem = item;
            body.classList.add('menu-open');
        },
        closeModal() {
            const body = document.querySelector('body');
            body.classList.remove('menu-open');
        },
        localizedDesc(activeItem) {
            switch (this.$i18n.locale) {
                case 'en':
                    return activeItem.description_eng;
                case 'et':
                    return activeItem.description;
                case 'ru':
                    return activeItem.description_rus;
                default:
                    return activeItem.description;
            }
        },
        async buy(id) {
            await fetch('https://app.35fit.com/api/store/buy', {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    item_id: id
                })
            })
                .then(response => response.json())
                .then(data => {

                    if (data.success) {
                        this.activeItem = null
                        if (data.data.payment_link) {
                            window.location.href = data.data.payment_link;
                            return
                        }
                        this.$emit('done', { message: "Purchased had been successful!" })
                    }
                    if (data.error) {
                        this.$emit('error', { message: data.error })
                    }

                })
                .catch(error => {
                    console.log(error);
                });
        }
    },
    components: { ModalWindow, MainButton },
    mounted() {
        // reorder store by id asc
        this.orderedStore = this.store
        this.orderedStore.sort((a, b) => a.id - b.id)
    }
}
</script>
<style>
.store-item-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    width: 100%;
}

.store-item-grid-column {
    background-color: var(--dark-gray);
    padding: 20px;
    border-radius: var(--card-br);
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.store-item-grid-column p {
    color: var(--text-gray);
    font-size: 13px;
}

.store-icon {
    max-width: 28px;
    max-height: 28px;
    aspect-ratio: auto;
}

.store-item-inner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 20px 0;
    gap: 20px;
}

.store-item-headings {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 5px;
}

.item-price {
    font-weight: 600;
    color: var(--text-gray);
    font-size: 16px;
    margin: 0;
}

.item-button {
    font: var(--font);
    color: var(--light-green);
    border-bottom: var(--light-green) 1px solid;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    margin-top: 10px;
}

.item-text-group p {
    margin: 10px 0;
    font-weight: 500;
    color: var(--text-gray);
    margin: 0;
}

.item-text-group h4 {
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
    word-wrap: break-word;
    max-width: 200px;
    white-space: nowrap;
}

.store-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 5px;
    margin: 15px 0;
}

.store-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 15px;
    padding: 5px;
}

.store-img {
    aspect-ratio: 1/1;
}

.store-img-inner {
    aspect-ratio: 3/2;

}

.store-img,
.store-img-inner {
    width: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: var(--card-br);
}

@media screen and (max-width: 1135px) {

    .item-text-group h4 {
        max-width: 180px;
    }
}

@media screen and (max-width: 1000px) {
    .item-text-group h4 {
        max-width: 160px;
    }
}

@media screen and (max-width: 850px) {
    .item-text-group h4 {
        max-width: 200px;
    }
}

@media screen and (max-width: 750px) {
    .item-text-group h4 {
        max-width: 180px;
    }
}

@media screen and (max-width: 700px) {
    .store-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 460px) {
    .item-text-group h4 {
        max-width: 180px;
    }
}

@media screen and (max-width: 420px) {
    .store-img-inner {
        aspect-ratio: 1/1;

    }

    .store-item-grid {
        grid-template-columns: 1fr;

    }

    .item-text-group h4 {
        max-width: 160px;
    }
}


@media screen and (max-width: 375px) {
    .item-text-group h4 {
        max-width: 130px;
    }

}
</style>
