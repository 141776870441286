<template>
    <div id="grid"
     class="pricing-grid">
        <PricingItem v-for="plan in itemsToShow"
         :key="plan.id"
         :id="plan.id"
         :plan="plan"
         @select="(plan) => select(plan)"
         :mode="mode" />
        <transition name="bounce">
            <div v-if="(this.selectedPlan)"
             class="subscribe-section">
                <div class="subscribe-section-wrapper">
                    <transition name="bounce"
                     mode="out-in">
                        <h4 :key="this.selectedPlan.name">
                            {{ this.selectedPlan.name.toUpperCase() }}
                        </h4>
                    </transition>
                    <MainButton :text="$t('pricing.other.confirm')"
                     @click="openModal" />
                    <button @click="cancelSelect"
                     class="cancel-subscription">{{ $t('pricing.other.cancel') }}</button>
                </div>
            </div>
        </transition>

        <transition name="bounce">
            <ModalWindow v-if="this.showModal"
             @closeModal="this.showModal = !this.showModal;"
             :title="$t('pricing.other.modal.title')"
             :subtitle="$t('pricing.other.modal.subtitle')">
                <transition name="bounce"
                 mode="out-in">
                    <LoaderView v-if="loader" />
                    <div v-else
                     class="modal-confirm-plan">
                        <img class="new-contract-img"
                         :src="contract"
                         alt="contract">
                        <transition name="bounce">
                            <InputNotify class="full-width"
                             v-if="warning.show"
                             :icon="warning.icon"
                             :text="warning.text" />
                        </transition>
                        <InputNotify class="full-width"
                         v-if="replacePlan.show"
                         :icon="replacePlan.icon"
                         :text="replacePlan.text" />
                        <PricingItem :plan="selectedPlan"
                         mode="plans"
                         :opened="true" />
                        <label class="checkbox-container">
                            <input type="checkbox"
                                   v-model="isPayWithStebby">
                            <span class="checkmark"></span>
                            <p>
                                {{ $t('pricing.other.payWithStebby') }}
                            </p>
                        </label>
                        <label class="checkbox-container">
                            <input type="checkbox"
                             v-model="confirmedConditions">
                            <span class="checkmark"></span>
                            <p>
                                {{ $t('pricing.other.acknowledge') }}
                                <a class="intext-link"
                                 target="_blank"
                                 :href="contractLink()">
                                    {{ $t('footer.contract') }}
                                </a>,
                                <a class="intext-link"
                                 target="_blank"
                                 href="/docs/Club_Rules.pdf">
                                    {{ $t('footer.rules') }}
                                </a> &
                                <a class="intext-link"
                                 target="_blank"
                                 :href="priceListLink()">
                                    {{ $t('pricing.other.pricing') }}
                                </a>
                            </p>
                        </label>
                        <transition name="bounce">
                            <MainButton v-if="confirmedConditions"
                             :text="$t('pricing.other.subscribe')"
                             :widthRestrict="true"
                             @click="subscribe"
                             :disabled="!confirmedConditions" />
                        </transition>
                    </div>
                </transition>
            </ModalWindow>
        </transition>
    </div>
</template>
<script>
import PricingItem from "./PricingItem.vue";
import MainButton from "./buttons/MainButton.vue";
import ModalWindow from "./ModalWindow.vue";
import contract from "@/assets/svg/contract.svg";
import InputNotify from "./InputNotify.vue";
import xmark from "@/assets/svg/xmark.svg";
import LoaderView from "./buttons/LoaderView.vue";
import router from "@/router";
import info from "@/assets/svg/info.svg";

export default {
    name: "PricingGrid",
    data() {
        return {
            selectedPlan: null,
            showModal: false,
            confirmedConditions: false,
            isPayWithStebby: false,
            contract,
            loader: false,
            xmark,
            itemsToShow: [],
            warning: {
                show: false,
                icon: "",
                text: ""
            },
            replacePlan: {
                show: true,
                icon: info,
                text: this.$t('pricing.other.planChange')
            }
        }
    },
    props: {
        json: {
            type: Object,
            required: true
        },
        mode: {
            type: String,
            default: "plans"
        }
    },
    components: {
        PricingItem,
        MainButton,
        ModalWindow,
        InputNotify,
        LoaderView
    },
    methods: {
        filterPlans() {
            if (this.mode == 'plans') {
                this.itemsToShow = this.json.plans.filter(plan => plan.show)
                return
            } else if (this.mode === 'servicesOnClassesPage') {
                const services = this.json.services.plans.filter (plan => (plan.id === 3 || plan.id === 4))
                const sgt = this.json.sgt.plans.filter (plan => (plan.id === 1 || plan.id === 3))
                const tests = this.json.tests.plans.filter (plan => (plan.id === 3))
                this.itemsToShow = [...sgt, ...tests, ...services]
                return
            }
            this.itemsToShow = this.json.plans
        },
        select(plan) {
            if (this.mode == 'plans') {
                this.selectedPlan = plan
                const newPlan = document.getElementById(plan.id);
                var prev = document.querySelector('.selected-plan');
                if (prev) {
                    prev.classList.remove('selected-plan');
                }
                newPlan.classList.add('selected-plan');
            }
        },
        cancelSelect() {
            this.selectedPlan = null
            var prev = document.querySelector('.selected-plan');
            if (prev) {
                prev.classList.remove('selected-plan');
            }

        },
        contractLink() {
            switch (this.$i18n.locale) {
                case 'en':
                    return 'https://app.35fit.com/resources/client_contract_ENG.pdf'
                case 'ru':
                    return 'https://app.35fit.com/resources/client_contract_RUS.pdf'
                default:
                    return 'https://app.35fit.com/resources/client_contract_ET.pdf'
            }
        },
        priceListLink() {
            switch (this.$i18n.locale) {
                case 'en':
                    return 'https://app.35fit.com/resources/price_list_ENG.pdf'
                case 'ru':
                    return 'https://app.35fit.com/resources/price_list_RUS.pdf'
                default:
                    return 'https://app.35fit.com/resources/price_list_ET.pdf'
            }
        },
        openModal() {
            if (localStorage.getItem('token')) {
                const body = document.querySelector('body');
                this.showModal = !this.showModal;
                body.classList.toggle('menu-open');
                return
            }
            this.$router.push('/login');
        },
        subscribe() {
            this.warning.show = false;
            this.loader = true;

            fetch('https://app.35fit.com/api/v2/users/change_ticket', {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' +
                        localStorage.getItem('token'),
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    package_id: this.selectedPlan.id,
                    payment_with_stebby: this.isPayWithStebby,
                })
            })
                .then(res => {
                    if (res.status !== 200) {
                        throw new Error(res.status);
                    }
                    return res.json();
                })
                .then(data => {
                    if (data.success) {
                        this.openModal();
                        router.push({
                            name: 'dashboard',
                            hash: '#contract'
                        });
                        return
                    }

                    this.warning = {
                        icon: this.xmark,
                        text: data.message,
                        show: true
                    }

                    this.loader = false;

                })
                .catch(err => {
                    console.log(err)
                    if (err.message == 401) {
                        this.openModal();
                        localStorage.removeItem('token');
                        this.$router.push('/login');
                    }
                    this.loader = false;
                })
        }

    },
    mounted() {
        this.filterPlans();
    },
}
</script>
<style>
#grid {
    scroll-margin-top: 250px;
}

.intext-link {
    color: var(--light-green);
    font-weight: 600;
}

.new-contract-img {
    width: 100%;
    max-width: 350px;
}

.modal-confirm-plan {
    margin: 20px 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
}

.cancel-subscription {
    color: var(--red);
    text-align: center;
    font-weight: 600 !important;
    font-size: 14px !important;
    font: var(--font);
}

.subscribe-section {
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    z-index: 99;
}

.subscribe-section-wrapper {
    position: relative;
    padding: 20px;
    width: 300px;
    display: flex;
    justify-content: center;
    gap: 10px;
    align-items: center;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 0.496);
    backdrop-filter: blur(20px);
    border-top-left-radius: var(--card-br);
    border-top-right-radius: var(--card-br);
}

.subscribe-section-wrapper h4 {

    text-align: center;
}

.plan-name h5 {
    font-size: 12px;
    color: var(--text-gray);
    text-transform: uppercase;
}

.plan-name h2 {
    text-transform: uppercase;
    font-size: 20px;
}

.pricing-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    position: relative;
}

.pricing-grid-card ul {
    display: flex;
    flex-direction: column;
    gap: 5px;
    font-size: 12px;
    color: var(--text-gray);
    font-weight: 500;
    width: 100%;
}

.bottom-margin {
    margin-bottom: 10px;
}

.pricing-grid-card ul li {
    display: flex;
    gap: 10px;
    line-height: 18px;
    align-items: center;
    width: 100%;
}

.list-icon {
    padding: 5px;
    width: 7px;
    height: 7px;
    border-radius: 30px;
    background-color: var(--light-green);
}

.pricing-card-small {
    font-size: 16px;
    text-wrap: nowrap;
    /* text wrap safari */
    white-space: nowrap;
}

.pricing-grid-card-top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 10px;
    gap: 10px;
}

.pricing-grid-card {
    padding: 20px;
    width: 100%;
    border-radius: var(--card-br);
    background-color: var(--gray);
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    transition: all 0.3s ease;
    border: 2px solid transparent;
    transition: all 0.2s ease-in-out;
}

.pricing-grid-card:hover {
    background-color: var(--dark-gray);
    cursor: pointer;
}

@media screen and (max-width: 570px) {
    .plan-name h2 {
        font-size: 16px;
    }

    .pricing-card-small {
        font-size: 14px;
    }

    .plan-name h5 {
        font-size: 10px;
    }

    .pricing-grid {
        grid-template-columns: 1fr;
    }




    .subscribe-section-wrapper {

        width: 100%;

    }
}

@media screen and (max-width: 400px) {}

@media screen and (max-width: 350px) {}
</style>
