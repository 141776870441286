import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'
import PricingView from '../views/PricingView.vue'
import Dashboard from '../views/DashboardView.vue'
import Register from '../views/RegisterView.vue'
import PageNotFound from '../views/PageNotFoundView.vue'
import TrainingView from '../views/TrainingView.vue'
import FaqView from '../views/FAQView.vue'
import SuccessView from '../views/SuccessView.vue'
import FailedView from '../views/FailedView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/faq',
    name: 'faq',
    component: FaqView
  },
  {
    path: '/pricing',
    name: 'pricing',
    component: PricingView
  },
  {
    path: '/dashboard/payment/success',
    name: 'payment-success',
    component: SuccessView,

  },
  {
    path: '/dashboard/payment/failed',
    name: 'payment-failed',
    component: FailedView,

  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('token')) {
        next('/dashboard')
      } else {
        next()
      }
    }
  },
  {
    path: '/register',
    name: 'register',
    component: Register,
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('token')) {
        next('/dashboard')
      } else {
        next()
      }
    }
  },
  {
    path: '/training',
    name: 'training',
    component: TrainingView
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('token')) {
        next()
      } else {
        next('/login')
      }
    }
  },
  {
    path: '/et/:pathMatch(.*)*',
    component: HomeView
  },
  {
    path: '/en/:pathMatch(.*)*',
    component: HomeView
  },
  { path: '/:pathMatch(.*)*', name: 'not-found', component: PageNotFound },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
