<template>
    <div class="input-notify">
        <img :src="icon"
         alt="Warning">
        {{ text }}
    </div>
</template>
<script>
import warning from '@/assets/svg/warning.svg'

export default {
    name: 'InputNotify',
    props: {
        icon: {
            type: String,
            default: warning
        },
        text: {
            type: String,
            required: true
        },
    },
    mounted() {

    }

}
</script>
<style>
.input-notify {
    padding: 20px;
    background-color: var(--gray);
    border-radius: var(--card-br);
    font-size: 14px;
    color: var(--text-gray);
    font-weight: 600;
    width: 100%;
    max-width: 550px;
    display: flex;
    align-items: center;
    gap: 15px;
}

.input-notify img {
    width: 20px;
    height: 20px;
}
</style>
