<template>

    <ModalWindow @closeModal="$emit('closeModal')"
     :title="activeOrder.show ? $t('dashboard.pages.gifts.order') + ' #' + activeOrder.id : $t('dashboard.pages.gifts.earnTitle')"
     :subtitle="activeOrder.show ? '' : $t('dashboard.pages.gifts.earnSubtitle')">
        <transition name="bounce"
         mode="out-in">
            <div v-if="activeOrder.show"
             class="order-inner">
                <button @click="activeOrder.show = false"
                 class="gifts-explain-button"
                 style="width: fit-content;">{{ $t('dashboard.pages.gifts.backToRules') }}</button>
                <img class="store-img-inner"
                 :src="baseUrl + storeItems.find(item => item.id == activeOrder.store_good_id).image"
                 alt="Order picture">
                <div class="rules-top-text">
                    <h2>{{ storeItems.find(item => item.id == activeOrder.store_good_id).name }}</h2>
                    <p>{{ storeItems.find(item => item.id == activeOrder.store_good_id).description }}</p>
                    <div>
                        <div class="space-between order-row">
                            <p>{{ $t('dashboard.pages.gifts.price') }}</p>
                            <p>{{ activeOrder.price / 100 }} {{ $t('dashboard.pages.gifts.euro') }}</p>
                        </div>
                        <div class="space-between order-row">
                            <p>{{ $t('dashboard.pages.gifts.orderDate') }}</p>
                            <p>{{ activeOrder.created.split('T')[0].split('-').reverse().join('.') }}</p>
                        </div>
                        <div class="space-between order-row">
                            <p>{{ $t('dashboard.pages.gifts.status') }}</p>
                            <p :class="paidStatus(activeOrder.status)">{{ activeOrder.status }}
                            </p>
                        </div>
                    </div>
                    <MainButton v-if="(activeOrder.status == 'pending')"
                     @click="payOrder(activeOrder.payment_link)"
                     :text="$t('dashboard.pages.invoices.pay')"
                     :widthRestrict="true" />
                </div>

            </div>

            <div v-else
             class="rules-inner">
                <div class="rules-card"
                 v-bind:style='{ backgroundImage: "url(" + giftsBackground + ")", }'>
                    <div>
                        <h6>{{ $t('dashboard.pages.gifts.optional') }}</h6>
                        <h2>{{ $t('dashboard.pages.gifts.additional') }}</h2>
                    </div>

                    <p>{{ $t('dashboard.pages.gifts.additionalText') }}</p>

                </div>
                <div class="rules-card"
                 v-bind:style='{ backgroundImage: "url(" + pointsBackground + ")", }'>
                    <div>
                        <h6>{{ $t('dashboard.pages.gifts.mandatory') }}</h6>
                        <h2>{{ $t('dashboard.pages.gifts.consistency') }}</h2>
                    </div>

                    <p v-if="trainings > 0">{{ $t('dashboard.pages.gifts.modalText', { trainings: trainings }) }}</p>
                    <p v-else>{{ $t('dashboard.pages.gifts.noPackage') }}</p>
                </div>
                <div class="rules-center">
                    <h5 v-if="trainings > 0">
                        {{ $t('dashboard.pages.gifts.currTraining', { trainings: trainings, currentTraining: currentTraining }) }}
                    </h5>
                    <VisitCalendar />
                </div>
                <div>
                    <h2>{{ $t('dashboard.pages.gifts.orders') }}</h2>
                    <div v-if="orders.length > 0"
                     class="order-list">
                        <ListRow v-for="order in orders"
                         :key="order.id"
                         :title="$t('dashboard.pages.gifts.order') + ' #' + order.id"
                         :date="(new Date(order.created_at).toLocaleDateString())"
                         :icon="doc"
                         @clicked="orderMode(order)" />
                    </div>
                    <div v-else
                     class="orders-list-empty">
                        <img class="no-info-pic"
                         :src="ordersPic"
                         alt="No orders">
                        <h3>{{ $t('dashboard.pages.gifts.noOrders') }}</h3>
                    </div>
                </div>
            </div>
        </transition>
    </ModalWindow>

</template>
<script>
import ModalWindow from '@/components/ModalWindow.vue';
import VisitCalendar from '../VisitCalendar.vue';
import pointsBackground from '../../../assets/svg/points-background.svg';
import ListRow from '../ListRow.vue';
import doc from '../../../assets/svg/doc.svg';
import ordersPic from '../../../assets/svg/orders.svg';
import MainButton from '@/components/buttons/MainButton.vue';
import giftsBackground from '../../../assets/svg/gifts-background.svg';


export default {
    name: 'EarningPointsModal',
    props: {
        trainings: {
            type: Number,
            default: 0
        },
        currentTraining: {
            type: Number,
            default: 0
        },
        orders: {
            type: Array,
            default: () => []
        },
        storeItems: {
            type: Array,
            default: () => []
        },
        baseUrl: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            pointsBackground,
            giftsBackground,
            doc,
            ordersPic,
            activeOrder: {
                show: false,
                id: null,
                price: null,
                status: null,
                created: null,
                store_good_id: null,
                payment_link: null,
            }
        }
    },
    components: {
        ModalWindow,
        VisitCalendar,
        ListRow,
        MainButton
    },
    methods: {
        paidStatus(status) {

            switch (status) {
                case 'pending':
                    return 'text-warning';
                case 'paid':
                    return 'green-text';
                case 'canceled':
                    return 'red-text';
                default:
                    return;
            }
        },
        orderMode(order) {
            this.activeOrder.show = !this.activeOrder.show;
            this.activeOrder.id = order.id;
            this.activeOrder.price = order.price;
            this.activeOrder.status = order.status;
            this.activeOrder.created = order.created_at;
            this.activeOrder.store_good_id = order.store_good_id;
            this.activeOrder.payment_link = order.payment_link;
        },
        payOrder(link) {
            window.location.href = link
        }
    }


}
</script>
<style>
.order-row {
    padding: 15px 0;
    border-bottom: 1px solid var(--dark-gray);
}

.order-row:last-of-type {
    border-bottom: none;
}


.order-row p {
    text-transform: capitalize;
}

.rules-top-text {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.rules-card {
    padding: 20px;
    padding-right: 31%;
    display: flex;
    position: relative;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    gap: 5px;
    min-height: 180px;
    background-color: var(--dark-gray);
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: 30%;
    border-radius: var(--card-br);
}

.rules-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.order-inner,
.order-list {
    margin: 20px 0;
    display: flex;
    flex-direction: column;

}

.orders-list-empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 25px;
    margin-top: 20px;
}

.order-inner {
    gap: 15px;
}

.rules-card p {
    font-size: 12px;
    color: var(--text-gray);
}

.rules-inner {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
</style>
