<template>
    <div v-if="activeAdvert"
     class="bottom-panel">
        <transition name="bounce"
         mode="out-in">
            <h4 :key="activeAdvert.id">{{ activeAdvert.promoText }}</h4>
        </transition>

        <button v-if="(activeAdvert.id == 2)"
         class="bottom-panel-button"
         @click="share">{{ this.$t('advert.share') }}</button>

        <RouterLink v-else-if="(activeAdvert.id == 3)"
         class="bottom-panel-button"
         :to="activeAdvert.link">
            {{ activeAdvert.buttonText }}
        </RouterLink>

        <RouterLink v-else-if="(!token)"
         @click="$emit('toRegister')"
         class="bottom-panel-button"
         :to="activeAdvert.link">
            {{ activeAdvert.buttonText }}
        </RouterLink>
    </div>
</template>

<script>


export default {
    name: 'BottomPanel',
    data() {
        return {
            toShowGroup: [
                // {
                //     id: 1,
                //     promoText: this.$t('advert.trialpromo'),
                //     buttonText: this.$t('advert.register'),
                //     link: "/register",
                //     show: localStorage.getItem('token') ? false : true
                // },
                /*{
                    id: 3,
                    promoText: this.$t('advert.summerBottomPanel'),
                    buttonText: this.$t('advert.buyNow'),
                    link: "/pricing",
                    show: true
                },*/
                {
                    id: 1,
                    promoText: this.$t('advert.standard'),
                    buttonText: this.$t('advert.register'),
                    link: "/register",
                    show: localStorage.getItem('token') ? false : true
                },
                {
                    id: 2,
                    promoText: this.$t('advert.test'),
                    buttonText: this.$t('advert.share'),
                    link: "/register",
                    show: true
                },
            ],
            activeAdvert: null,
            token: localStorage.getItem('token'),
            width: localStorage.getItem('token') ? 'unset' : '45vw'
        }
    },
    methods: {
        // func to change adverts every 10 seconds while true
        changeAdverts() {
            let i = 0;
            setInterval(() => {
                if (i < this.toShowGroup.length) {
                    if (this.toShowGroup[i].show) {
                        this.activeAdvert = this.toShowGroup[i];
                    }
                    i++;
                } else {
                    i = 0;
                }
            }, 3000);
        },
        share() {
            if (navigator.canShare) {
                try {
                    navigator.share({
                        title: '35FIT',
                        text: 'Join me at 35FIT - first AI-like gym in Tallinn! Get a present up a contract conclusion',
                        url: 'https://35fit.com'
                    })
                } catch (error) {
                    console.log(error);
                }
            }

        },
    },
    mounted() {
        this.activeAdvert = this.toShowGroup[0];
        this.changeAdverts();
    }
}

</script>

<style scoped>
.bottom-panel {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 68px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--header-padding);
    box-sizing: border-box;
    font-size: 14px;
    background-color: rgba(255, 255, 255, 0.496);
    backdrop-filter: blur(20px);
    z-index: 98;
}

h4 {
    max-width: v-bind(width);
}

button {
    font: var(--font);
}

.bottom-panel-button {
    background-color: var(--red);
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    padding: 10px 20px;
    border-radius: var(--card-br);
    text-decoration: none;
    transition: all 0.3s ease-in-out;
}
</style>
