<template >
    <section id="gallery">
        <h2>{{ $t('faq.gallery') }}</h2>

        <div class="gallery">
            <button class="item-button gallery-btn"
             @click="togglePreview">show more</button>
            <div class="gallery-column">
                <img src="https://35fit.s3.eu-north-1.amazonaws.com/gallery/gallery_1.jpg"
                 class="gallery-preview" />
                <img src="https://35fit.s3.eu-north-1.amazonaws.com/gallery/gallery_2.jpg"
                 class="gallery-preview" />
            </div>
            <div class="gallery-column">
                <img src="https://35fit.s3.eu-north-1.amazonaws.com/gallery/gallery_3.jpg"
                 class="gallery-preview" />
                <img src="https://35fit.s3.eu-north-1.amazonaws.com/gallery/gallery_4.jpg"
                 class="gallery-preview" />
            </div>
            <button class="gallery-arrow"
             @click="togglePreview"><img src="@/assets/svg/arrow-black.svg"
                 alt="arrow"
                 class="arrow-right" /></button>
        </div>


        <transition name="side">

            <PreviewPhoto v-if="previewOpened"
             :photos="photos"
             @close="togglePreview" />

        </transition>
    </section>
</template>
<script>
import PreviewPhoto from './PreviewPhoto.vue'

export default {
    components: {
        PreviewPhoto
    },
    data() {
        return {
            previewOpened: false,
            photos: [

            ],
        }
    },
    methods: {
        togglePreview() {
            this.previewOpened = !this.previewOpened;

            this.previewOpened ? this.stopScroll() : this.startScroll();
        },
        addPhotos() {
            for (let i = 1; i <= 15; i++) {
                this.photos.push(`https://35fit.s3.eu-north-1.amazonaws.com/gallery/gallery_${i}.jpg`);
            }
        },
        stopScroll() {
            document.body.style.overflow = 'hidden';
        },

        startScroll() {
            document.body.style.overflow = 'auto';
        }
    },
    beforeMount() {
        this.addPhotos();
    }
}
</script>
<style>
#gallery {
    display: block;
    margin: 80px auto;
    max-width: calc(960px + 1rem);
}

#gallery h2 {
    margin-bottom: 25px;
}

.gallery-arrow {
    margin-left: 10px;
    transition: all .2s ease-in-out;
}

.gallery-arrow:active {
    scale: 0.9;
}

.gallery-btn {
    display: none;

}

.gallery {
    display: flex;
    gap: 10px;
}

.gallery-column {
    display: flex;
    gap: 10px;
    flex-direction: column;
}

.gallery-preview {
    /* max-height: 220px; */
    max-width: 100%;
    border-radius: 13px;
    object-fit: cover;
}

.arrow-right {
    rotate: -90deg;
    border-radius: 50%;
    background-color: #D9D9D9;
}


@media screen and (max-width: 768px) {

    .gallery-arrow {
        display: none;
    }

    .gallery {
        flex-direction: column-reverse;
    }

    .gallery-btn {
        display: flex;
        align-self: center;
        margin-bottom: 20px;
    }

    .gallery-preview {
        max-height: 300px;
    }

}

@media screen and (max-width: 660px) {

    #gallery {
        padding: var(--padding);
    }
}
</style>
