<template>
    <section>

        <div class="training-feature">
            <h2>{{ $t('training.groupTraining.section1.h1') }}</h2>
            <h2 class="gray">
                {{ $t('training.groupTraining.section1.h2') }}
            </h2>
            <CardsGrid :cardsContent="cardsContent" />
        </div>

        <div class="training-feature">
            <h2>{{ $t('training.groupTraining.section2.h1') }}</h2>
            <h2 class="gray">
                {{ $t('training.groupTraining.section2.h2') }}
            </h2>
            <CardsGrid :cardsContent="cardsContent2" />
            <LearnMoreButton :text="$t('learnMore')"
             class="center-btn"
             @click="openModal(1)" />
        </div>

        <div class="training-feature">
            <h2>{{ $t('training.groupTraining.section3.h1') }}</h2>
            <h2 class="gray">{{ $t('training.groupTraining.section3.h2') }}</h2>
            <GrayCardUnited :content="grayCardsContent" />
        </div>

        <div class="training-feature">
            <h2>{{ $t('training.groupTraining.media.h1') }}</h2>
            <h2 class="gray">{{ $t('training.groupTraining.media.h2') }}</h2>
            <MediaContent :imageMode="equipmentStudio.mode"
             :heading="equipmentStudio.heading"
             :image="equipmentStudio.image"
             :text="equipmentStudio.text"
             :modalTitle="equipmentStudio.modalTitle"
             :subheading="equipmentStudio.subheading"
             :modalSubtitle="equipmentStudio.modalSubtitle">
                <MediaContentInner :image="media5"
                 :title="$t('training.groupTraining.media.inner.title')"
                 :text="$t('training.groupTraining.media.inner.text')" />

                <MediaContentInner :image="media4"
                 :title="$t('training.groupTraining.media.inner.title2')"
                 :text="$t('training.groupTraining.media.inner.text2')" />
            </MediaContent>

            <MediaContent imageMode="small-reverse"
             :heading="$t('training.groupTraining.media.mediaContent.first.heading')"
             subheading=""
             :image="bootyMedia"
             :text="$t('training.groupTraining.media.mediaContent.first.text')"
             :showButton="false" />

            <MediaContent imageMode="small"
             :heading="$t('training.groupTraining.media.mediaContent.second.heading')"
             subheading=""
             :image="trx"
             :text="$t('training.groupTraining.media.mediaContent.second.text')"
             :showButton="false" />
        </div>


        <Transition name="bounce">
            <ModalWindow v-if="showModal1"
             :title="$t('training.groupTraining.media.modal.title')"
             :subtitle="$t('training.groupTraining.media.modal.subtitle')"
             @closeModal="(showModal1 =
             !showModal1)">
                <MediaContentInner :image="media1"
                 :title="$t('training.groupTraining.media.modal.first.title')"
                 :text="$t('training.groupTraining.media.modal.first.text')" />

                <MediaContentInner :image="media2"
                 :title="$t('training.groupTraining.media.modal.second.title')"
                 :text="$t('training.groupTraining.media.modal.second.text')" />

                <MediaContentInner :image="media3"
                 :title="$t('training.groupTraining.media.modal.third.title')"
                 :text="$t('training.groupTraining.media.modal.third.text')" />

            </ModalWindow>
        </Transition>
    </section>
</template>
<script>
import CardsGrid from '../CardsGrid.vue';
import card1 from '@/assets/img/training/pp/pp1.png';
import card2 from '@/assets/img/training/pp/pp2.png';
import card3 from '@/assets/img/training/pp/pp3.png';
import card4 from '@/assets/img/training/pp/booty.png';
import card5 from '@/assets/img/training/pp/strong.png';
import card6 from '@/assets/img/training/pp/zen.png';
import media1 from '@/assets/img/training/pp/media1.webp'
import media2 from '@/assets/img/training/pp/media2.webp'
import media3 from '@/assets/img/training/pp/media3.webp'
import media4 from '@/assets/img/training/pp/media4.webp'
import media5 from '@/assets/img/training/pp/media5.webp'
import mediaMain from '@/assets/img/training/pp/mediaMain.png'
import bootyMedia from '@/assets/img/training/pp/BootyBuilder.png'
import trx from '@/assets/img/training/pp/TRX.png'
import icon1 from '@/assets/svg/flame.svg'
import icon2 from '@/assets/svg/flex.svg'
import icon3 from '@/assets/svg/thumb.svg'
import LearnMoreButton from '../buttons/LearnMoreButton.vue';
import ModalWindow from '../ModalWindow.vue';
import MediaContentInner from '../MediaContentInner.vue';
import GrayCardUnited from '@/components/GrayCardUnited.vue'
import MediaContent from '../MediaContent.vue';




export default {
    name: "GroupTraining",
    components: { CardsGrid, LearnMoreButton, ModalWindow, MediaContentInner, GrayCardUnited, MediaContent },
    data() {
        return {
            showModal1: false,
            showModal2: false,
            media1,
            media2,
            media3,
            media4,
            media5,
            bootyMedia,
            trx,
            cardsContent: [{
                id: 1,
                title: this.$t('training.groupTraining.section1.card1.title'),
                text: this.$t('training.groupTraining.section1.card1.text'),
                image: card1
            },
            {
                id: 2,
                title: this.$t('training.groupTraining.section1.card2.title'),
                text: this.$t('training.groupTraining.section1.card2.text'),
                image: card2
            },
            {
                id: 3,
                title: this.$t('training.groupTraining.section1.card3.title'),
                text: this.$t('training.groupTraining.section1.card3.text'),
                image: card3
            }],
            cardsContent2: [{
                id: 1,
                title: this.$t('training.groupTraining.section2.card1.title'),
                text: this.$t('training.groupTraining.section2.card1.text'),
                image: card4
            },
            {
                id: 2,
                title: this.$t('training.groupTraining.section2.card2.title'),
                text: this.$t('training.groupTraining.section2.card2.text'),
                image: card5
            },
            {
                id: 3,
                title: this.$t('training.groupTraining.section2.card3.title'),
                text: this.$t('training.groupTraining.section2.card3.text'),
                image: card6
            }],
            grayCardsContent: [
                {
                    id: 1,
                    icon: icon1,
                    heading: this.$t('training.groupTraining.section3.card1.heading'),
                    text: this.$t('training.groupTraining.section3.card1.text'),
                },
                {
                    id: 2,
                    icon: icon2,
                    heading: this.$t('training.groupTraining.section3.card2.heading'),
                    text: this.$t('training.groupTraining.section3.card2.text'),
                },
                {
                    id: 3,
                    icon: icon3,
                    heading: this.$t('training.groupTraining.section3.card3.heading'),
                    text: this.$t('training.groupTraining.section3.card3.text'),
                }
            ],
            equipmentStudio: {
                mode: 'small',
                heading: this.$t('training.groupTraining.media.heading'),
                image: mediaMain,
                subheading: '',
                text: this.$t('training.groupTraining.media.text'),
                modalTitle: this.$t('training.groupTraining.media.modalTitle'),
                modalSubtitle: this.$t('training.groupTraining.media.modalSubtitle')
            },
        }
    },
    methods: {
        openModal(mode) {
            const body =
                document.querySelector('body');

            switch (mode) {
                case 1:
                    this.showModal1 = !this.showModal1;
                    break
                case 2:
                    this.showModal2 = !this.showModal2;
                    break
                default:
                    break
            }
            body.classList.add('menu-open');
        }
    }
}
</script>
<style>

</style>
