<template>
    <button>{{ text }}</button>
</template>
<script>

export default {

    props: {
        text: {
            type: String,
            default: 'Vaata rohkem'
        }
    },
}
</script>
<style scoped>
button {
    background-color: var(--dark-blue);
    color: white;
    border-radius: var(--button-br);
    padding: 10px 30px;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
    transition: all .2s ease-in-out;
    font-family: var(--font);
}

button:hover {
    background-color: var(--lighter-blue);
}

button:active {
    background-color: var(--dark-blue);
    transform: scale(.95);
}
</style>
