import { createApp } from 'vue'
import router from './router'
import App from './App.vue'
import VueCookies from 'vue-cookies';
import i18n from './i18n'
import { createMetaManager } from "vue-meta";
import { plugin as vueMetaPlugin } from "vue-meta";
import Particles from "vue3-particles";


createApp(App)
    .use(router)
    .use(VueCookies)
    .use(i18n)
    .use(vueMetaPlugin)
    .use(createMetaManager())
    .use(Particles)
    .mount('#app')

