<template>
    <div ref="alert"
     @touchstart="blockScroll()"
     @touchmove="close($event)"
     @touchend="unlockScroll()"
     class="alert">
        <div class="alert-inner">
            <div class="alert-icon">
                <img :src="icon"
                 alt="icon">
            </div>
            <div class="alert-text">
                <h5>{{ title }}</h5>
                <p>{{ text }}</p>
            </div>
        </div>
    </div>
</template>
<script>
import test from '@/assets/svg/testresult.svg'

export default {
    name: "NotifyWarning",
    props: {
        title: String,
        text: String,
        icon: {
            type: String,
            default: test
        },
        color: String
    },
    data() {
        return {
            test: test
        }
    },
    methods: {
        blockScroll() {
            let body = document.body;
            let alert = this.$refs.alert;
            body.classList.add('menu-open');
            alert.style.scale = 1.05;
        },
        unlockScroll() {
            let body = document.body;
            let alert = this.$refs.alert;
            body.classList.remove('menu-open');
            alert.style.scale = 1;
        },
        close(e) {
            // if swiped up
            let alert = this.$refs.alert;
            // get vertical position of alert
            e.preventDefault();

            if (e.changedTouches[0].clientY <= 10) {
                alert.style.transform = `translateY(${e.changedTouches[0].clientY}px)`;

                if (e.changedTouches[0].clientY <= 10) {
                    alert.style.transform = `translateY(-150px)`;
                    // unmount component
                    setTimeout(() => {
                        this.$emit('close');
                    }, 500);
                }
            }
        },
        closeByTimer() {
            let alert = this.$refs.alert;
            if (alert) {
                alert.style.transform = `translateY(-150%)`;
                // unmount component

                setTimeout(() => {
                    this.$emit('close');
                }, 500);
            }
        }

    },
    mounted() {
        let alert = document.querySelector('.alert-inner');
        alert.classList.add(this.color);

        setTimeout(() => {
            this.closeByTimer();
        }, 3000);
    },
}
</script>
<style scoped>
.alert {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 110;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.5s ease;
    animation: enter 0.5s ease;
}

@keyframes enter {
    0% {
        transform: translateY(-100%);
    }

    100% {
        transform: translateY(0);
    }
}

.alert-inner {
    border-radius: var(--card-br);
    padding: 20px;
    width: 100%;
    max-width: 600px;
    display: flex;
    align-items: center;
    gap: 15px;
    backdrop-filter: blur(10px);
    box-shadow: var(--shadow);
}

.red {
    background-color: rgba(255, 0, 0, 0.151);
}

.info {
    background-color: rgba(130, 130, 207, 0.339);
}

.default {
    background-color: rgba(255, 255, 255, 0.9);
}

.green {
    background-color: rgba(85, 247, 85, 0.473);
}

.alert-text {
    display: flex;
    flex-direction: column;
    gap: 3px;
    cursor: default;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.alert-text h5 {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: default;
}

.alert-icon {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.alert-icon img {
    height: 20px;
}

.alert-text p {
    font-size: 12px;
}
</style>
