<template>
    <div ref="sceneStart"
     class="card-united">
        <div ref="block"
         v-for="row in content"
         :key="row.id"
         class="row">
            <div class="icon-group">
                <img ref='icon'
                 class="icon"
                 :src="row.icon"
                 alt="icon">
            </div>
            <div class="text-group">
                <h3 class="textgroup-heading">{{ row.heading }}</h3>
                <p class="textgroup-text">
                    {{ row.text }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

export default {
    props: {
        content: Array
    },
    data() {
        return {

        }
    },
    methods: {
        animate() {
            const block = this.$refs.block;
            const sceneStart = this.$refs.sceneStart;
            const icon = this.$refs.icon;

            const tl = gsap.timeline({
                scrollTrigger: {
                    trigger: sceneStart,
                    start: 'top 70%',
                    end: 'bottom 70%',
                    toggleActions: 'play none none reverse'
                },
                defaults: {
                    ease: 'power3.inOut',
                    duration: 0.5,
                }
            });

            // animate sceneStart, wait until the animation is finished and then animate the blocks inside
            tl.from(sceneStart, {
                opacity: 0,
                y: -15,

            }, 'start')
                .from(block, {
                    opacity: 0,
                    y: 15,
                    stagger: 0.3,

                }, 'start-=0.5')

            // animate the icons
            tl.from(icon, {
                rotate: '+=720deg',
                stagger: 0.3,
                duration: 1,

            }, 'start+=0.5')

        },

    },
    mounted() {
        this.animate();
    },

}
</script>

<style scoped>
.card-united {
    background-color: var(--dark-gray);
    border-radius: var(--card-br);
    padding: 22px;
    margin: 25px 0;
    color: var(--text-gray);
    column-gap: 15px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
}

.row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 12px;
}

.icon {
    max-width: 28px;
    max-height: 28px;
    aspect-ratio: auto;
}

.icon-group {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
}

.textgroup-heading {
    color: black;
}

.text-group {
    width: fit-content;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.textgroup-text {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
}

@media screen and (max-width: 625px) {

    .icon-group {
        max-width: 88px;
        max-height: 88px;

    }

    .card-united {
        grid-template-columns: 1fr;
    }

    .text-group {
        gap: 10px;
    }

    .textgroup-text {
        font-size: 12px;
    }
}
</style>
