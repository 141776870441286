<template>
    <div class="no-cotract">
        <img class="no-cotract-img"
         :src="noContract"
         alt="No contract">
        <div class="no-contract-group">
            <div>
                <h3>{{ $t('dashboard.pages.noContract.title') }}</h3>
                <p>{{ $t('dashboard.pages.noContract.text') }}</p>
            </div>
            <router-link to="/pricing">
                <MainButton :text="$t('dashboard.pages.noContract.toPricing')" />
            </router-link>

        </div>
    </div>
</template>
<script>
import noContract from '@/assets/svg/no-contract.svg'
import MainButton from '../buttons/MainButton.vue';

export default {
    name: 'NoContract',
    data() {
        return {
            noContract,
        }
    },
    components: {
        MainButton
    }
}
</script>
<style>
.no-cotract-img {
    width: 250px;
}

.no-contract-group {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.no-cotract {
    width: 100%;
    padding: 40px;
    border-radius: var(--card-br);
    background-color: var(--gray);
    position: relative;
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: space-around;
}

@media screen and (max-width: 1020px) {
    .no-cotract {
        flex-direction: column;
        gap: 35px;
    }

    .no-contract-group div {
        text-align: center;
        display: flex;
        flex-direction: column;
        gap: 6px;
    }

    .no-contract-group {
        gap: 25px;
    }
}

@media screen and (max-width: 600px) {
    .no-cotract {
        padding: 20px;
    }

    .no-contract-group div p {
        font-size: 14px;
    }
}
</style>
