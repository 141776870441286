<template>
    <div class="cookie-warning">
        <img class="cookie-cookie"
         :src="cookies"
         alt="cookies">
        <h4>{{ $t('cookiehead') }}</h4>
        <p v-html="$t('cookie')"
         class="cookie-text">
        </p>
        <MainButton :text="$t('close')"
         @click="closeCookie()" />
    </div>
</template>

<script>
import cookies from '@/assets/svg/cookies.svg';
import MainButton from '../buttons/MainButton.vue';

export default {
    components: {
        MainButton,
    },
    data() {
        return {
            cookies,
        }
    },
    methods: {
        closeCookie() {
            this.cookie = false;
            // save to cookies that user has seen the cookie warning with time limit of 2 days
            this.$cookies.set('cookie', 'seen', '2d');
            this.$emit('close');
        },
    }

}
</script>

<style>
.cookie-warning {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    border-radius: var(--card-br);
    background-color: var(--bg);
    z-index: 100;
    padding: 1rem;
    max-width: 375px;
    display: flex !important;
    flex-direction: column;
    gap: 15px;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    color: var(--text);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.cookie-text {
    font-size: 12px;
    text-align: center;
    font-weight: 400;
    line-height: 18px;
}

.cookie-text a {
    color: var(--light-green);
    font-weight: 500;
}

.cookie-cookie {
    width: 35px;
    height: 35px;
}

@media screen and (max-width: 520px) {
    .cookie-warning {
        bottom: 0.5rem;
        right: 0.5rem;
        left: 0.5rem;
        max-width: unset;
    }
}
</style>
