<template>
    <div class="test-results">
        <div class="test-grid">
            <div class="test-top tile tile-space-between">
                <div class="tile-inline">
                    <img :src="testresult" class="test-icon" alt="test result">
                    <div class="tile-column">
                        <h4>Dynostics Nutrition</h4>
                        <p>3rd of Jan, 2023</p>
                    </div>
                </div>

                <a class="download-test" href="#">PDF</a>
            </div>
            <div class="test-middle-left tile tile-column tile-space-between">
                <div class="tile-column">
                    <p class="tile-small-text">Base metabolism</p>
                    <h3>1890 cal.</h3>
                </div>

                <p class="tile-difference">
                    10% lower
                    <img :src="down" class="intext-icon" alt="down">
                </p>
            </div>
            <div class="test-middle-right tile tile-column tile-space-between">
                <div class="tile-column">
                    <p class="tile-small-text">Active metabolism</p>
                    <h3>2490 cal.</h3>
                </div>

                <p class="tile-difference">
                    5% upper
                    <img :src="up" class="intext-icon" alt="up">
                </p>
            </div>
            <div class="test-bottom tile tile-space-between">
                <div class="tile-column tile-column-width">
                    <p class="tile-small-text">Carbon.</p>
                    <h3>50%</h3>
                </div>
                <div class="tile-column tile-column-width">
                    <p class="tile-small-text">Fats</p>
                    <h3>25%</h3>
                </div>
                <div class="tile-column tile-column-width">
                    <p class="tile-small-text">Proteins</p>
                    <h3>25%</h3>
                </div>
            </div>
        </div>
        <h4>Recommendation</h4>
        <p class="recommended-text">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Unde sequi molestias fuga
            vitae optio. Corrupti
            aperiam molestiae fugit illum facere eius vel pariatur amet natus eos, temporibus sequi sapiente nostrum.
        </p>
    </div>
</template>
<script>
import testresult from '@/assets/svg/testresult.svg'
import down from '@/assets/svg/down.svg'
import up from '@/assets/svg/up.svg'

export default {
    data() {
        return {
            testresult,
            down,
            up
        }
    }

}
</script>
<style>
.download-test {
    font-size: 14px;
    color: var(--light-green);
    font-weight: 600;
    padding: 0;
    cursor: pointer;
    text-align: right;
}

.test-grid {
    margin-top: 15px;
    margin-bottom: 25px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    gap: 10px;
    width: 100%;
}

.tile-column-width {
    width: 100px;
}

.test-icon {
    width: 15px;
}

.intext-icon {
    height: 13px;
}

.tile-difference {
    display: flex;
    align-items: center;
    gap: 5px;
}

.recommended-text {
    font-size: 14px;
    line-height: 1.5;
    color: var(--text-gray);
    margin-top: 10px;
}

.tile {
    padding: 20px;
    background-color: var(--dark-gray);
    border-radius: var(--card-br);
}

.tile-inline {
    display: flex;
    align-items: center;
    gap: 15px;
}

.tile-space-between {
    display: flex;
    justify-content: space-between !important;
    align-items: center;
}

.tile-column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    justify-content: center;
}

.tile p {
    font-size: 13px;
    color: var(--text-gray);
}


.test-top {
    grid-area: 1 / 1 / 2 / 3;
}

.test-middle-left {
    grid-area: 2 / 1 / 3 / 2;
}

.test-middle-right {
    grid-area: 2 / 2 / 3 / 3;
}

.test-middle-right,
.test-middle-left {
    height: 150px;
}

.test-bottom {
    grid-area: 3 / 1 / 4 / 3;
}
</style>
