<template>
    <ModalWindow :title="$t('dashboard.pages.contract.paymentModal.title')"
     :subtitle="$t('dashboard.pages.contract.paymentModal.subtitle')"
     @closeModal="$emit('close')">
        <div class="stebby-group">
            <OptionsSelect style="margin-top: 20px;"
             :options="paymentOptions"
             mode="single"
             @selected="item => selectedPayment = item" />
            <InputNotify class="full-width"
             v-if="warning.show && selectedPayment == 'stebby'"
             :icon="warning.icon"
             :text="warning.text" />
            <Transition name="bounce">
                <div class="stebby-group"
                 v-if="selectedPayment == 'stebby'">
                    <h4>{{ $t('dashboard.pages.contract.paymentModal.enter') }}</h4>
                    <input class="stebby-input uppercase"
                     type="text"
                     placeholder="WXDKASZ31S41"
                     v-model="stebbyCode">
                </div>
            </Transition>

            <Transition name="side">
                <MainButton v-if="selectedPayment"
                 class="marg-top marg-bot"
                 :widthRestrict="true"
                 :text="(selectedPayment == 'stebby') ? $t('dashboard.pages.contract.paymentModal.code') : $t('dashboard.pages.invoices.pay')"
                 @click="pay()" />
            </Transition>
        </div>
    </ModalWindow>
</template>
<script>
import OptionsSelect from '../selectors/OptionsSelect.vue';
import InputNotify from '../InputNotify.vue';
import ModalWindow from '../ModalWindow.vue';
import MainButton from '../buttons/MainButton.vue';
import stebby from '@/assets/img/dashboard/stebby.png'
import paysera from '@/assets/img/dashboard/paysera.svg'
import xmark from '@/assets/svg/xmark.svg'

export default {
    name: 'PaymentModal',
    components: {
        OptionsSelect,
        ModalWindow,
        MainButton,
        InputNotify
    },
    watch: {
        stebbyCode() {
            this.stebbyCode = this.stebbyCode.replace(/[^a-zA-Z0-9]/g, '')
            if (this.stebbyCode.length > 12) {
                this.stebbyCode = this.stebbyCode.slice(0, 12)
            }
        }
    },
    methods: {
        pay() {
            if (this.selectedPayment == 'paysera') {
                this.$emit('pay', { method: this.selectedPayment })
                return
            }

            // allow only latin letters and numbers

            if (this.stebbyCode != '' && this.stebbyCode.length == 12) {
                this.$emit('pay', { method: this.selectedPayment, ticket_code: this.stebbyCode })
                this.unlockScroll()
            } else {
                this.warning.show = true
            }
        },
        unlockScroll() {
            const body = document.querySelector('body');
            body.classList.toggle('menu-open');
        }
    },
    data() {
        return {
            warning: {
                show: false,
                icon: xmark,
                text: this.$t('dashboard.pages.contract.paymentModal.warning')
            },
            paymentOptions: [
                {
                    id: 'stebby',
                    name: 'Stebby',
                    shortText: this.$t('dashboard.pages.contract.paymentModal.stebbyText'),
                    icon: stebby
                },
                {
                    id: 'paysera',
                    name: 'Paysera',
                    shortText: this.$t('dashboard.pages.contract.paymentModal.payseraText'),
                    icon: paysera
                },

            ],
            selectedPayment: null,
            stebbyCode: '',
        }

    }
}
</script>
<style>
.uppercase {
    text-transform: uppercase;
}
</style>
