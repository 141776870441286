<template>
    <main class="faq-page">

        <section class="page-start">
            <h1 class="page-name">{{ $t('faq.pagename') }}.
                <span class="gray">{{ $t('faq.title') }}</span>
            </h1>
        </section>

        <div class="faq-separator"
         :style="{ 'background-image': 'url(' + cover + ')' }"></div>

        <div class="faq">

            <FaqPage />

        </div>

    </main>
</template>
<script>
import FaqPage from '../components/layout/FaqPage.vue'
import cover from '../assets/img/faq/faq.webp';
import { useMeta } from 'vue-meta'

export default {
    name: 'FAQView',
    components: {
        FaqPage,
    },
    data() {
        return {
            cover,
            vh: window.visualViewport.height + 'px',
        }
    },
    beforeMount() {
        useMeta({
            title: this.$t('pagenames.faq'),
            link: [
                { rel: 'canonical', href: 'https://35fit.com/faq' },
            ],
            htmlAttrs: {
                lang: this.$i18n.locale,
            },
            meta: [
                { property: "og:title", content: "35FIT | " + this.$t('pagenames.faq') + " | AI gym" },
                { property: "og:description", content: "Lepingu, arvelduse, ajakava ja muu teabe leiate siit." },
                { property: "og:url", content: "https://35fit.com/faq" },
            ],
            description: "Lepingu, arvelduse, ajakava ja muu teabe leiate siit.",
        })
    },

}
</script>
<style>
.faq-page {
    display: block;
    background-color: white;
    width: 100%;
}

.faq-separator {
    width: 100%;
    height: v-bind(vh);
    background-position: 80%;
    background-size: cover;
    background-repeat: no-repeat;
}
</style>
